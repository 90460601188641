import { EventEmitter, Injectable } from '@angular/core';
import { DatabaseService } from '../api/database.service';
import { BrandArea } from '../models/brandarea';
import { Job } from '../models/job';
import { User } from '../models/user';
import { FeatureGroupEnum } from '../shared/user-rights-management/feature-group.enum';
import { AuthSession } from 'aws-amplify/auth';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  session?: AuthSession;
  user?: User;
  brandAreas: BrandArea[] = [];
  job: Job[] = [];

  _isInitialized = false;
  _initEvent = new EventEmitter<void>();

  constructor(private databaseService: DatabaseService) {}

  initStore(session: AuthSession): void {
    this.session = session;
    this.user = new User(session);

    this._isInitialized = true;
    this.getInitEventEmitter().emit();
  }

  isInitialized(): boolean {
    return this._isInitialized;
  }

  getInitEventEmitter(): EventEmitter<void> {
    return this._initEvent;
  }

  /**
   * Return true if the connected user is part of the specified group in cognito, else returns false
   *
   * @param group
   */
  userHasGroup(group: FeatureGroupEnum): boolean {
    return this.user?.groups.includes(group) ?? false;
  }

  async getBrandAreas(): Promise<BrandArea[]> {
    return await this.databaseService.listBrandAreas().then();
  }
}
