import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  DeletedAllLogs,
  DeletedLog,
  DownloadedLog,
  ProductLog,
  ProductLogS3Object,
} from '../../../../models/backend/product-logs';
import { Shadow, ShadowState, UpdateShadow } from '../../../../models/shadow';
import { HttpClient, HttpContext } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ThingsService {
  private readonly backendUrl = environment.backendUrl;

  constructor(private readonly http: HttpClient) {}

  /**
   * Fetches a thing's shadow
   *
   * @param thingName
   */
  getThingShadow(thingName: string): Observable<Shadow> {
    this.http
      .get<{ state: ShadowState }>(
        `${this.backendUrl}/things/${thingName}/shadow`,
        {
          context: new HttpContext(),
        },
      )
      .pipe(map((result) => new Shadow(result.state)));

    return this.http
      .get<{
        state: ShadowState;
      }>(`${this.backendUrl}/things/${thingName}/shadow`)
      .pipe(map((result) => new Shadow(result.state)));
  }

  /**
   * Updates a thing's shadow
   *
   * @param thingname
   * @param payload
   */
  updateThingShadow(
    thingname: string,
    payload: UpdateShadow,
  ): Observable<Partial<ShadowState>> {
    return this.http
      .put<{
        state: Partial<ShadowState>;
      }>(`${this.backendUrl}/things/${thingname}/shadow`, payload)
      .pipe(map((result) => result.state));
  }

  /**
   * Gets a list of existing product logs files, whether they are registered on the thing's name or its mac address.
   *
   * @param thingName
   * @param mac
   */
  getProductLogsList(thingName: string, mac: string): Observable<ProductLog[]> {
    return combineLatest([
      this.http
        .get<
          ProductLogS3Object[]
        >(`${this.backendUrl}/products/${thingName}/logs`)
        .pipe(
          map((thingNameLogs) =>
            thingNameLogs.map((rawLog) => new ProductLog(thingName, rawLog)),
          ),
        ),
      this.http
        .get<ProductLogS3Object[]>(`${this.backendUrl}/products/${mac}/logs`)
        .pipe(
          map((macNameLogs) =>
            macNameLogs.map((rawLog) => new ProductLog(mac, rawLog)),
          ),
        ),
    ]).pipe(map((logs: ProductLog[][]) => logs.flat()));
  }

  /**
   * Downloads a product logs file by generating a presigned url on the bakend, then calling S3 for download.
   *
   * @param thingNameOrMac
   * @param filename
   */
  downloadLogFile(
    thingNameOrMac: string,
    filename: string,
  ): Observable<DownloadedLog> {
    return this.http
      .get<{
        presignedUrl: string;
      }>(`${this.backendUrl}/products/${thingNameOrMac}/logs/${filename}`)
      .pipe(
        switchMap((getPresignedResult) =>
          this.http.get(getPresignedResult.presignedUrl, {
            responseType: 'blob',
          }),
        ),
        switchMap((blob) => blob.text()),
        map((rawFileStr) => new DownloadedLog(rawFileStr)),
      );
  }

  /**
   * Gets a list of existing product logs files, whether they are registered on the thing's name or its mac address.
   *
   * @param thingName
   * @param mac
   */
  purgeLogs(thingName?: string, mac?: string): Observable<boolean> {
    return combineLatest([
      thingName
        ? this.http.delete<DeletedAllLogs>(
            `${this.backendUrl}/products/${thingName}/logs`,
          )
        : of(undefined),
      mac
        ? this.http.delete<DeletedAllLogs>(
            `${this.backendUrl}/products/${mac}/logs`,
          )
        : of(undefined),
    ]).pipe(map((logs) => logs.every((_res) => !!_res?.success)));
  }

  /**
   * Deletes the given logfile for a given product
   *
   * @param thingName
   * @param mac
   * @param filename
   */
  deleteLog(
    thingName: string | undefined,
    mac: string | undefined,
    filename: string,
  ): Observable<boolean> {
    return combineLatest([
      thingName
        ? this.http.delete<DeletedLog>(
            `${this.backendUrl}/products/${thingName}/logs/${filename}`,
          )
        : of(undefined),
      mac
        ? this.http.delete<DeletedLog>(
            `${this.backendUrl}/products/${mac}/logs/${filename}`,
          )
        : of(undefined),
    ]).pipe(map((logs) => logs.every((_res) => !!_res?.success)));
  }
}
