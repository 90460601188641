import { Injectable } from '@angular/core';
import { AwsService } from '../lib/aws.service';
import * as Iot from 'aws-sdk/clients/iot';
import { ListJobsRequest, ListJobsResponse } from 'aws-sdk/clients/iot';
import { Observable } from 'rxjs';
import { from } from 'rxjs';
import { GroupOfThings } from '../models/Group-of-things.model';
import CONFIG from '../../config';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { GroupsOfThingsService } from './groups-of-things.service';

@Injectable({
  providedIn: 'root',
})
export class JobsService {
  constructor(
    private readonly awsService: AwsService,
    private readonly groupsService: GroupsOfThingsService,
  ) {}

  listJobs(params: ListJobsRequest): Observable<ListJobsResponse> {
    return from(
      this.awsService
        .iot()
        .listJobs({
          maxResults: 250,
          ...params,
        })
        .promise(),
    );
  }

  async getJob(jobId: string): Promise<Iot.Job> {
    const res = await this.awsService.iot().describeJob({ jobId }).promise();
    if (!res.job) {
      throw new Error('Missing job!');
    }
    return res.job;
  }

  async cancelJob(jobId: string): Promise<void> {
    await this.awsService.iot().cancelJob({ jobId }).promise();
  }

  async getJobDocument(jobId: string): Promise<Iot.JobDocument> {
    const res = await this.awsService.iot().getJobDocument({ jobId }).promise();
    if (!res.document) {
      throw new Error('Missing job document!');
    }
    return res.document;
  }

  async listDevicesForJob(
    jobId: string,
    status?: string,
    nextToken?: string,
  ): Promise<Iot.ListJobExecutionsForJobResponse> {
    return this.awsService
      .iot()
      .listJobExecutionsForJob({ jobId, status, maxResults: 250, nextToken })
      .promise();
  }

  /**
   * Fetches the groupId associated with a job in dynamo.
   * If the group still exists, also fetches its details.
   *
   * @param jobId the job id to look for
   * @return <ul><li> null if no group is associated with this job</li>
   *  <li>a string containing `[deleted]{groupName}` if the group no longer exists</li>
   *  <li>The group details if a linked group exists</li></ul>
   */
  public getGroupForJob(
    jobId: string,
  ): Observable<GroupOfThings | string | null> {
    return from(
      this.awsService
        .dynamodb()
        .get({
          TableName: CONFIG.metaversionJobsTable,
          Key: { jobId },
        })
        .promise(),
    ).pipe(
      switchMap((response) => {
        if (
          !response.Item?.groupId ||
          typeof response.Item.groupId !== 'string'
        ) {
          // No group, return null
          return of(null);
        }

        if (response.Item.groupId.startsWith('[deleted]')) {
          // DeletedGroup, return the name stored in groupId field
          return of(response.Item.groupId);
        }

        // Existing group, fetch its details
        return this.groupsService.getGroup(response.Item.groupId);
      }),
    );
  }
}
