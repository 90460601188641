import { AwsService } from '../lib/aws.service';
import { Injectable } from '@angular/core';
import {
  DescribeThingResponse,
  SearchIndexResponse,
} from 'aws-sdk/clients/iot';
import CONFIG from '../../config';
import {
  MacAddress,
  ThingData,
  ThingGroup,
  ThingType,
} from '../models/thingtype';
import { ThingTypesService } from './thing-types.service';
import { AnalyticsManagerService } from '../lib/analytics-manager.service';
import * as moment from 'moment';
import { DatabaseService } from './database.service';
import { CriteriaKey, ParsedCriteriaKey } from '../models/firmware';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UuidUtils } from '../shared/utils/uuid.utils';

export type NextToken = string & { _: 'NextToken' };

export interface StatisticsLine {
  label: string;
  count: number;
}

export interface DeployCount {
  totalCount: number;
  details: DeployCountDetail[];
}

export interface DeployCountDetail {
  count: number;
  label: string;
  criteriaEntries: [key: string, value: string][];
}

@Injectable({
  providedIn: 'root',
})
export class RegistryService {
  constructor(
    private aws: AwsService,
    private thingTypesService: ThingTypesService,
    private dbService: DatabaseService,
    private analyticsManagerService: AnalyticsManagerService,
  ) {}

  async getThing(thingName: string): Promise<DescribeThingResponse> {
    return await this.aws.iot().describeThing({ thingName }).promise();
  }

  async paginateListDevices(
    queryString: string = '',
    nextToken?: string,
  ): Promise<SearchIndexResponse> {
    return this.aws
      .iot()
      .searchIndex({
        indexName: 'AWS_Things',
        queryString,
        maxResults: CONFIG.searchMaxResults,
        nextToken,
      })
      .promise();
  }

  countDeployGroups(
    thingGroups: ThingGroup[],
    metaversionId: string,
  ): Observable<DeployCountDetail[]> {
    return combineLatest(
      thingGroups.map((thingGroup) =>
        this.countDeployThings(thingGroup.thingGroupName),
      ),
    ).pipe(
      map((counts: number[]): DeployCountDetail[] =>
        counts.map((count, index) => {
          const criteriaKey = thingGroups[index].criteriaKey;
          const parsedKey = ParsedCriteriaKey.fromCriteriaKey(
            criteriaKey as CriteriaKey,
          );
          if (parsedKey.valuesAreOnlyNA()) {
            return {
              count,
              label: `devices with version ${metaversionId}`,
            } as DeployCountDetail;
          }

          const key = ParsedCriteriaKey.fromCriteriaKey(
            criteriaKey as CriteriaKey,
          );
          const criterias = Object.entries(key).filter(
            ([, value]) => value !== 'NA',
          );

          return {
            count,
            label: 'devices for criteria',
            criteriaEntries: criterias,
          } as DeployCountDetail;
        }),
      ),
    );
  }

  async countDeployThings(thingGroupName: string): Promise<number> {
    let count = 0;
    let nextToken: string | undefined;

    do {
      const result = await this.aws
        .iot()
        .listThingsInThingGroup({
          thingGroupName,
          maxResults: 250,
          nextToken,
        })
        .promise();

      count += result?.things?.length ?? 0;
      nextToken = result?.nextToken;
    } while (nextToken);

    return count;
  }

  async getDailyNewDevicesStats(
    thingType: ThingType,
    numberOfDays: number = 7,
  ): Promise<StatisticsLine[]> {
    const allThingTypes: ThingType[] = (await this.thingTypesService
      .getThingTypes()
      .toPromise()) as ThingType[];

    const days: string[] = [];
    for (let i = numberOfDays - 1; i >= 0; i--) {
      days.push(moment.utc().subtract(i, 'day').format('YYYY-MM-DD'));
    }

    const items = await this.analyticsManagerService.fetchStatsDays(days);

    return days.map((day) => {
      let count = 0;
      const possiblyUndefined = items.find(
        (_) => _ !== undefined && _.day === day,
      );
      if (possiblyUndefined !== undefined) {
        if (thingType === '') {
          for (const existingThingType of allThingTypes) {
            count += parseFloat(
              possiblyUndefined[`${existingThingType}_count`] || '0',
            );
          }
        } else {
          count = parseFloat(possiblyUndefined[`${thingType}_count`] || '0');
        }
      }

      return { label: day, count };
    });
  }

  async getStatistics(
    thingType: ThingType,
  ): Promise<{ wifi: StatisticsLine[]; ui: StatisticsLine[] }> {
    const wifiVersions: string[] = [];
    const uiVersions: string[] = [];

    // Get enable metaversion
    const metaVersions = await this.dbService.listMetaVersions(1);

    const filtered = metaVersions.filter(
      (_) => _.thingType === thingType && _.enabledDate,
    );

    // Keep only the first metaversion
    const metaVersion = filtered[0];

    if (metaVersion?.wifiFirmware !== undefined) {
      wifiVersions.push(metaVersion.wifiFirmware.version);
    }
    if (metaVersion?.uiFirmware !== undefined) {
      uiVersions.push(metaVersion.uiFirmware.version);
    }

    // Get last firmware
    const firmwares = await this.dbService.listFirmwares(1, thingType);
    firmwares.sort((a, b) => (a.date > b.date ? -1 : 1));
    for (const firmware of firmwares) {
      switch (firmware.type) {
        case 'wifi':
          if (
            wifiVersions.length !== 4 &&
            wifiVersions.indexOf(firmware.version) === -1
          ) {
            wifiVersions.push(firmware.version);
          }
          break;
        case 'ui':
          if (
            uiVersions.length !== 4 &&
            uiVersions.indexOf(firmware.version) === -1
          ) {
            uiVersions.push(firmware.version);
          }
          break;
      }

      if (wifiVersions.length === 4 && uiVersions.length === 4) {
        break;
      }
    }

    try {
      let wifiOthers = 0;
      const wifiStatTotal =
        await this.analyticsManagerService.fetchStatsVersions(
          thingType,
          'fw_wifi',
        );
      if (wifiStatTotal?.statistics?.count !== undefined) {
        wifiOthers = wifiStatTotal.statistics.count;
      }

      const wifiRes = await Promise.all(
        wifiVersions.map((version) =>
          this.analyticsManagerService.fetchStatsVersions(
            thingType,
            'fw_wifi',
            version,
          ),
        ),
      );

      const wifi: StatisticsLine[] = [];
      wifiRes.forEach((value, index) => {
        const stats = value.statistics;
        wifi.push({
          label: wifiVersions[index],
          count: stats?.count ? stats.count : 0,
        });

        if (stats?.count) {
          wifiOthers -= stats.count;
        }
      });

      if (wifiOthers > 0) {
        wifi.push({
          label: 'Others',
          count: wifiOthers,
        });
      }

      let uiOthers = 0;
      const uiStatTotal = await this.analyticsManagerService.fetchStatsVersions(
        thingType,
        'fw_ui',
      );
      if (uiStatTotal?.statistics?.count !== undefined) {
        uiOthers = uiStatTotal.statistics.count;
      }
      const uiRes = await Promise.all(
        uiVersions.map((version) =>
          this.analyticsManagerService.fetchStatsVersions(
            thingType,
            'fw_ui',
            version,
          ),
        ),
      );

      const ui: StatisticsLine[] = [];
      for (let i = 0; i < uiRes.length; i++) {
        const stats = uiRes[i].statistics;
        if (stats !== undefined && stats.count !== undefined) {
          ui.push({
            label: uiVersions[i],
            count: stats.count,
          });

          uiOthers -= stats.count;
        }
      }

      if (uiOthers > 0) {
        ui.push({
          label: 'Others',
          count: uiOthers,
        });
      }

      return { wifi, ui };
    } catch (e) {
      throw new Error('ThrottlingException');
    }
  }

  /**
   * Returns the ThingData from its identifier
   *
   * @param searchedThingType the ThingType of reference
   * @param identifier either a macAddress, a full thingName (type + serial), or a SerialNumber
   * @throws an Error if the identifier is wrong, doesn't exist, or its ThingType doesn't match the reference searchedThingType
   */
  public async searchThing(
    searchedThingType: string,
    identifier: string,
  ): Promise<ThingData | undefined> {
    let foundSerialNumber: string;
    let foundThingType: string;

    if (identifier.includes(':')) {
      // mac address
      const _thing = await this.dbService.search(identifier as MacAddress);
      foundSerialNumber = _thing.serialnumber;
      foundThingType = _thing.thingType;
    } else if (UuidUtils.isValidUuid(identifier)) {
      // plain serial number
      foundSerialNumber = identifier;
      foundThingType = searchedThingType;
    } else {
      // thing name (thingtype + serial)
      const [tmpFoundThingType, ...restFoundSerialNumber] =
        identifier.split('-');
      foundThingType = tmpFoundThingType;
      foundSerialNumber = restFoundSerialNumber.join('-');
    }

    if (foundThingType !== searchedThingType) {
      throw new Error(
        `Incompatible Thing Type ("${foundThingType}") with the target Thing Type of Group ("${searchedThingType}")`,
      );
    }
    if (!UuidUtils.isValidUuid(foundSerialNumber)) {
      throw new Error(`Given SerialNumber "${foundSerialNumber}" is invalid`);
    }

    const describedThing = await this.getThing(
      `${searchedThingType}-${foundSerialNumber}`,
    );
    if (!describedThing?.thingArn) {
      throw new Error("Thing doesn't exist");
    }

    return Promise.resolve(ThingData.fromDescribeResponse(describedThing));
  }
}
