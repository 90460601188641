import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuditAction, AuditType } from '@common/audit-log/models/AuditLog';
import { combineLatest, firstValueFrom, from, Observable, of } from 'rxjs';
import { catchError, map, mapTo, switchMap, tap } from 'rxjs/operators';
import CONFIG from '../../../../../config';
import { AuditService } from '../audit/audit.service';
import { FirmwareService } from '../firmware/firmware.service';
import { DatabaseService } from '../../../database.service';
import { StorageService } from '../../../storage.service';
import { BinaryFile } from '../../../../models/backend/firmware/binary-file';
import { CreateFirmwareRequest } from '../../../../models/backend/firmware/create-firmware-request';
import { CreateFirmwareResponse } from '../../../../models/backend/firmware/create-firmware-response';
import { FirmwareType } from '../../../../models/backend/firmware/firmware-type';
import {
  CmmfKey,
  ExtractedFirmwareData,
  ExtractedFirmwareDataWrapper,
  Firmware,
  FirmwareFileWrapper,
} from '../../../../models/firmware';
import { NotificationService } from '../../../../shared/notification.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FirmwareFileService {
  private readonly backendUrl = environment.backendUrl;

  constructor(
    private readonly notif: NotificationService,
    private readonly storageService: StorageService,
    private readonly firmwareService: FirmwareService,
    private readonly dataBaseService: DatabaseService,
    private readonly auditService: AuditService,
    private readonly http: HttpClient,
  ) {}

  /**
   * Builds the S3 filename (key), appending the bootloader if given
   *
   * @param firmware
   * @param bootloader
   * @private
   */
  static buildS3KeyWithBootloader(
    firmware: Firmware,
    bootloader?: string,
  ): string {
    // Find file extension in S3 Key (last dot after last slash)
    const lastSlashIndex = firmware.newS3Key?.file.lastIndexOf('/') ?? 0;
    const lastDotIndex = firmware.newS3Key?.file
      .substr(lastSlashIndex + 1)
      .lastIndexOf('.');
    if (lastDotIndex === -1) {
      // No file extension, append
      return `${firmware.newS3Key?.file}_${bootloader}`;
    } else {
      // File extension found, insert just before
      const lastDotIndexFull = firmware.newS3Key?.file.lastIndexOf('.') ?? 0;
      const prefix = firmware.newS3Key?.file.substr(0, lastDotIndexFull);
      const suffix = firmware.newS3Key?.file.substr(lastDotIndexFull + 1);
      return `${prefix}_${bootloader}.${suffix}`;
    }
  }

  /**
   * Generates the S3Key for the given firmware and file input
   *
   * @param firmware
   * @param filename the uploaded filename
   */
  static generateS3Key(firmware: Firmware, filename: string): string {
    if (
      firmware.thingType.length === 0 ||
      firmware.type.length === 0 ||
      !firmware.newS3Key
    ) {
      throw new Error('Incomplete firmware!');
    }

    if (!filename) {
      throw new Error('Missing firmware file!');
    }

    const folder = firmware.newS3Key.isSigned
      ? CONFIG.signedFolder
      : CONFIG.unsignedFolder;
    return [
      folder,
      firmware.thingType.toUpperCase(),
      firmware.type.toUpperCase(),
      firmware.version,
      [
        firmware.newS3Key.range || 'NA',
        firmware.newS3Key.cmmf || 'NA',
        firmware.newS3Key.indice || 'NA',
      ].join('_'),
      firmware.newS3Key.brandArea || 'NA',
      filename,
    ].join('/');
  }

  static isCMMFValid(cmmf: string): boolean {
    return /^[0-9]{10}$/.test(cmmf);
  }

  /**
   * Handles creating the firmware and the outcome with Toasts
   *
   * @param firmware The firmware to create
   * @param firmwareFileWrapper the firmware file data to upload
   * @param additionalCmmfs to handle several more CMMF
   * @private
   */
  createFirmwareAndHandleToast(
    firmware: Firmware,
    firmwareFileWrapper: FirmwareFileWrapper,
    additionalCmmfs: string[] = [],
  ): Observable<string> {
    return this.createFirmware(
      firmware,
      firmwareFileWrapper,
      additionalCmmfs,
    ).pipe(
      tap(
        (firmwareId) => {
          if (additionalCmmfs.length > 1) {
            this.notif.showSuccess(
              `Created and uploaded binary for firmware with generated id ${firmwareId}`,
            );
          }
        },
        (e) => {
          if (e.code === 'ConditionalCheckFailedException') {
            this.notif.showError(
              `Firmware ${firmware.type.toUpperCase()} "${firmware.version}" already exists!`,
              e,
            );
          }
          throw e;
        },
      ),
    );
  }

  /**
   * Handles calling DB to add the firmware, and uploads the file to S3
   *
   * @param firmware The firmware to create
   * @param firmwareFileWrapper the firmware file data to upload
   * @param additionalCmmfs If given, updates the firmware to add other S3Keys ("Firmware file") for each CMMF
   * @private
   */
  public createFirmware(
    firmware: Firmware,
    firmwareFileWrapper: FirmwareFileWrapper,
    additionalCmmfs: string[] = [],
  ): Observable<string> {
    if (!firmware.newS3Key) {
      throw new Error('Incomplete firmware!');
    }
    const newS3Key = firmware.newS3Key;

    let temporaryFilename: string;
    let uploadFirmware$: Observable<unknown>;

    // In case of a wifi firmware, we need to upload it on the temporary S3 before creating the metaversion.
    // In case of an ui firmware, this has already been done while extracting data from the binary file.
    if (firmwareFileWrapper.file && firmwareFileWrapper.filename) {
      temporaryFilename = `${Date.now()}_${firmwareFileWrapper.filename}`;
      uploadFirmware$ = this.storageService.uploadFirmwareFile(
        firmwareFileWrapper.file,
        temporaryFilename,
        CONFIG.temporaryFirmwaresBucket,
      );
    } else if (firmwareFileWrapper.sourceFilename) {
      temporaryFilename = firmwareFileWrapper.sourceFilename;
      uploadFirmware$ = of(void 0);
    } else {
      throw new Error(
        'Input needs to contain either file + filename or sourceFilename',
      );
    }

    const request: CreateFirmwareRequest = {
      type: firmware.type as FirmwareType,
      thingType: firmware.thingType,
      releaseNote: firmware.releaseNote ?? 'No release note was provided',
      criteriaType: firmware.criteriaType,
      binaries: [firmware.newS3Key.cmmf, ...additionalCmmfs].map(
        (cmmf: string | undefined): BinaryFile => ({
          file: temporaryFilename,
          cmmf,
          brandArea: newS3Key.brandArea,
          indice: newS3Key.indice,
          range: newS3Key.range,
          bootloader: newS3Key.bootloader,
          signed: newS3Key.isSigned,
        }),
      ),
    };

    return uploadFirmware$.pipe(
      switchMap(() =>
        this.http.put<CreateFirmwareResponse>(
          `${this.backendUrl}/firmwares/${firmware.version}`,
          request,
        ),
      ),
      map((res: CreateFirmwareResponse): string => res.firmwareId),
    );
  }

  /**
   * Updates a firmware with a new S3Key and the uploaded file
   * If given additionalCmmfs, also copies the file
   *
   * @param firmware firmware to be updated
   * @param firmwareFileWrapper the firmware file data to upload
   * @param additionalCmmfs additional cmmfs to copy the firmware
   */
  public updateFirmware(
    firmware: Firmware,
    firmwareFileWrapper: FirmwareFileWrapper,
    additionalCmmfs: string[] = [],
  ): Observable<Firmware[]> {
    const filename: string | undefined =
      firmware.type === 'ui'
        ? firmwareFileWrapper.filename
        : firmwareFileWrapper.file?.name;

    if (!firmware.newS3Key || !filename) {
      throw new Error('Incomplete firmware!');
    }

    firmware.newS3Key.file = FirmwareFileService.generateS3Key(
      firmware,
      filename,
    );

    return from(this.uploadFirmware(firmware, firmwareFileWrapper)).pipe(
      tap(async () => {
        await this.dataBaseService.updateFirmware(
          firmware,
          firmware.newS3Key?.getCriteriaKey(),
        );
      }),
      switchMap(() =>
        this.handleFirmwareFileCopies(additionalCmmfs, firmware, filename),
      ),
    );
  }

  /**
   * Calls the uploadFile method and handles error
   *
   * @param file
   * @param s3Key
   * @param firmware
   */
  uploadFirmwareFile(
    file: File,
    s3Key: string,
    firmware: Firmware,
  ): Observable<Firmware> {
    return this.storageService
      .uploadFirmwareFile(file, s3Key, CONFIG.firmwaresBucket)
      .pipe(
        catchError((e) => {
          console.error(e);
          throw new Error(
            `Something wrong happened when uploading the file "${file.name}" for the Firmware "${firmware.id}".` +
              `Please Retry(Details: code ${e.statusCode} "${e.message ?? e.code}")`,
          );
        }),
        map(() => firmware),
      );
  }

  extractFirmwareData(file: File): Observable<ExtractedFirmwareDataWrapper> {
    const timestamp = Date.now();
    const tempFilename = `${timestamp}_${file.name}`;

    return this.storageService.uploadTempFile(file, tempFilename).pipe(
      map(() =>
        this.storageService.getSignedUrl(
          tempFilename,
          CONFIG.temporaryFirmwaresBucket,
        ),
      ),
      switchMap((signedUrl) =>
        this.firmwareService.getExtractedFirmwareData(signedUrl).pipe(
          catchError((err) => {
            console.error('Error extracting firmware data', err);
            return of({});
          }),
        ),
      ),
      map((extractedData) => ({
        tempFilename,
        extractedData,
      })),
    );
  }

  /**
   * Adds a CMMF either into the firmware, or to the "additional cmmfs" array
   *
   * @param firmware the firmware to add the CMMF to
   * @param cmmf the added CMMF
   * @param additionalCmmfs mutable array to add the CMMF into
   */
  helpHandlingAddingCMMF(
    firmware: Firmware,
    cmmf: string,
    additionalCmmfs: string[],
  ): void {
    const trimmedCmmf = cmmf.trim();
    if (trimmedCmmf === '') {
      return;
    }

    if (firmware.newS3Key && !firmware.newS3Key?.cmmf) {
      firmware.newS3Key.cmmf = trimmedCmmf as CmmfKey;
    } else if (
      !additionalCmmfs.includes(trimmedCmmf) &&
      firmware.newS3Key?.cmmf !== trimmedCmmf
    ) {
      additionalCmmfs.push(trimmedCmmf);
    }
  }

  /**
   * Removing a CMMF will either remove it from the firmware or remove it from the "additional cmmfs" array
   * If the removed CMMF is from the firmware, but additional CMMFs exist in the "additional cmmf",
   * then the first element is removed to be set in the Firmware
   *
   * @param firmware The firmware which CMMF to handle (Should be uiFirmware)
   * @param removedCmmf the removed CMMF
   * @param additionalCmmfs mutable array to remove the CMMF from
   */
  helpHandlingRemovingCMMF(
    firmware: Firmware,
    removedCmmf: string,
    additionalCmmfs: string[] = [],
  ): void {
    if (firmware.newS3Key?.cmmf === removedCmmf) {
      firmware.newS3Key.cmmf = additionalCmmfs.shift() as CmmfKey;
    } else {
      const findIndex = additionalCmmfs.findIndex(
        (_additionalCmmf) => _additionalCmmf === removedCmmf,
      );
      if (findIndex > -1) {
        additionalCmmfs.splice(findIndex, 1);
      }
    }
  }

  /**
   * Simply checks if data has any value inside by checking it has properties (object keys)
   *
   * @param data
   */
  hasExtractedData(data: ExtractedFirmwareData): boolean {
    return !!data && Object.keys(data).length > 0;
  }

  /**
   * Uploads a firmware with the given firmware file data
   *
   * @param firmware
   * @param firmwareFileWrapper the firmware file data to upload
   */
  private async uploadFirmware(
    firmware: Firmware,
    firmwareFileWrapper: FirmwareFileWrapper,
  ): Promise<Firmware> {
    if (
      firmware.thingType.length === 0 ||
      firmware.type.length === 0 ||
      !firmware.newS3Key
    ) {
      throw new Error('Incomplete firmware!');
    }

    if (
      (firmware.type === 'ui' && !firmwareFileWrapper?.filename) ||
      (firmware.type === 'wifi' && !firmwareFileWrapper.file?.name)
    ) {
      throw new Error('Missing firmware file!');
    }

    this.notif.showSuccess(
      `Uploading ${firmwareFileWrapper.filename ?? firmwareFileWrapper.file?.name}`,
    );
    let s3Key = firmware.newS3Key.file;
    if (firmware.newS3Key.bootloader) {
      s3Key = FirmwareFileService.buildS3KeyWithBootloader(
        firmware,
        firmware.newS3Key.bootloader,
      );
    }

    if (firmwareFileWrapper.file) {
      await this.uploadFirmwareFile(
        firmwareFileWrapper.file,
        s3Key,
        firmware,
      ).toPromise();
    } else if (
      firmwareFileWrapper.sourceFilename &&
      firmwareFileWrapper.sourceBucket &&
      firmwareFileWrapper.targetBucket
    ) {
      await this.storageService
        .copyFile(
          firmwareFileWrapper.sourceFilename,
          s3Key,
          firmwareFileWrapper.sourceBucket,
          firmwareFileWrapper.targetBucket,
        )
        .toPromise();
    } else {
      throw new Error('Missing data for uploading Firmware');
    }

    this.auditService.pushEvent({
      type: AuditType.FIRMWARE,
      action: AuditAction.ADD_FILE,
      resourceId: firmware.id,
      additionalData: {
        filename: firmwareFileWrapper.filename,
      },
    });

    // If the firmware is presigned, but no bootloader is given, then copy it to a new one with a "0.0.0" suffix
    if (firmware.isNewSigned() && !firmware.newS3Key.bootloader) {
      const s3KeyNoBootloader = FirmwareFileService.buildS3KeyWithBootloader(
        firmware,
        '0.0.0',
      );
      await this.copyFirmwareFile(s3Key, s3KeyNoBootloader).toPromise();
      this.auditService.pushEvent({
        type: AuditType.FIRMWARE,
        action: AuditAction.ADD_FILE,
        resourceId: firmware.id,
        additionalData: {
          filename: firmwareFileWrapper.filename,
        },
      });
    }

    return firmware;
  }

  /**
   * Copies the S3 file with the given originalS3Key to the copyS3Key target
   *
   * @param originalS3Key
   * @param copyS3Key
   */
  private copyFirmwareFile(
    originalS3Key: string,
    copyS3Key: string,
  ): Observable<void> {
    return this.storageService
      .copyFile(
        originalS3Key,
        copyS3Key,
        CONFIG.firmwaresBucket,
        CONFIG.firmwaresBucket,
      )
      .pipe(
        catchError((e) => {
          console.error(e);
          throw new Error(
            `Something wrong happened when copying the original file to "${copyS3Key}".` +
              `<br><strong>Please Retry</strong>.<br>(Details: code ${e.statusCode} "${e.message ?? e.code}")`,
          );
        }),
        mapTo(void 0),
      );
  }

  /**
   * Copies the firmware for each additionalCmmfs
   *
   * @param additionalCmmfs each cmmf to duplicate firmware
   * @param firmware the firmware to duplicate
   * @param filename the filename of the uploaded firmware
   */
  private handleFirmwareFileCopies(
    additionalCmmfs: string[],
    firmware: Firmware,
    filename: string,
  ): Observable<Firmware[]> {
    if (additionalCmmfs.length === 0) {
      return of([firmware]);
    }

    // Creates more S3Keys ("Firmware Files") to update the firmware with them, for each CMMF
    return combineLatest(
      additionalCmmfs.map((_cmmf) =>
        from(this.updateFirmwareForCmmf(firmware, _cmmf, filename)),
      ),
    ).pipe(map((additionalFirmwares) => [firmware, ...additionalFirmwares]));
  }

  /**
   * Creates S3Keys ("Firmware File") for each CMMF and updates the firmware accordingly
   * NB: the file is not reuploaded, it is copied from the original file
   *
   * @param firmware The original firmware from which copy the information
   * @param cmmf The current CMMF to add
   * @param filename the filename of the uploaded firmware
   */
  private async updateFirmwareForCmmf(
    firmware: Firmware,
    cmmf: string,
    filename: string,
  ): Promise<Firmware> {
    const copyFirmware = firmware.clone();

    if (!firmware.newS3Key || !copyFirmware.newS3Key) {
      return Promise.reject();
    }

    copyFirmware.newS3Key.cmmf = cmmf as CmmfKey;
    copyFirmware.newS3Key.file = FirmwareFileService.generateS3Key(
      copyFirmware,
      filename,
    );

    let originalS3Key = firmware.newS3Key.file;
    let copyS3Key = copyFirmware.newS3Key.file;
    if (firmware.newS3Key.bootloader) {
      originalS3Key = FirmwareFileService.buildS3KeyWithBootloader(
        firmware,
        firmware.newS3Key.bootloader,
      );
      copyS3Key = FirmwareFileService.buildS3KeyWithBootloader(
        copyFirmware,
        firmware.newS3Key.bootloader,
      );
    }

    return (await firstValueFrom(
      this.copyFirmwareFile(originalS3Key, copyS3Key).pipe(
        tap(async () => {
          if (
            copyFirmware.isNewSigned() &&
            !copyFirmware.newS3Key?.bootloader
          ) {
            const s3KeyNoBootloader =
              FirmwareFileService.buildS3KeyWithBootloader(
                copyFirmware,
                '0.0.0',
              );
            await firstValueFrom(
              this.copyFirmwareFile(originalS3Key, s3KeyNoBootloader),
            );
            this.auditService.pushEvent({
              type: AuditType.FIRMWARE,
              action: AuditAction.ADD_FILE,
              resourceId: firmware.id,
              additionalData: {
                filename,
              },
            });
          }
          await this.dataBaseService.updateFirmware(
            copyFirmware,
            copyFirmware.newS3Key?.getCriteriaKey(),
          );
        }),
        map(() => copyFirmware),
      ),
    )) as Firmware;
  }
}
