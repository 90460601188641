import { Injectable } from '@angular/core';
import { CrossOriginService } from './cross-origin.service';
import { TeamsMessageCard } from '../models/teams-message-card.model';
import { Observable } from 'rxjs';
import CONFIG from '../../config';

@Injectable({
  providedIn: 'root',
})
export class TeamsService {
  constructor(private readonly crossOrigin: CrossOriginService) {}

  sendMessage(message: TeamsMessageCard): Observable<unknown> {
    return this.crossOrigin.post(CONFIG.teamsWebhokUrl, message);
  }
}
