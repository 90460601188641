import { ThingDocument } from 'aws-sdk/clients/iot';
import { Shadow } from './shadow';
import * as moment from 'moment';
import { ThingAttributes, ThingData } from './thingtype';
export class ThingListDisplay {
  constructor(
    public name: string,
    public groups?: string,
    public connectionTime?: Date | null,
    public connectionTimeStr?: string | null,
    public currentFirmware?: string | null,
    public firmwareDetails?: { wifi?: string; ui?: string },
    public nextFirmwareReported?: string,
    public nextFirmwareDesired?: string,
    public attributes?: ThingAttributes,
  ) {}

  static fromThingDocuments(
    thingDocuments?: ThingDocument[],
  ): ThingListDisplay[] {
    return thingDocuments?.map(this.fromThingDocument) ?? [];
  }

  static fromThingDocument(thingDocument: ThingDocument): ThingListDisplay {
    const name = thingDocument.thingName ?? '';

    const shadow = new Shadow(JSON.parse(thingDocument.shadow ?? '{}'));

    let lastConnection = null;
    let connectionTime = null;
    let connectionTimeStr = null;

    if (shadow.lastConnection()) {
      lastConnection = moment(shadow.lastConnection());
      connectionTime = lastConnection.toDate();
      connectionTimeStr = lastConnection.format('YYYY-MM-DD HH:mm:ss');
    }

    const firmwareDetails = {
      wifi: shadow.reportedFirmware()?.files.find((_) => _.type === 'wifi')
        ?.version,
      ui: shadow.reportedFirmware()?.files.find((_) => _.type === 'ui')
        ?.version,
    };
    const currentFirmwware =
      (firmwareDetails?.wifi ? `WIFI ${firmwareDetails.wifi}` : '') +
      (firmwareDetails.wifi && firmwareDetails.ui ? ' - ' : '') +
      (firmwareDetails?.ui ? `UI ${firmwareDetails.ui}` : '');
    const nextFirmwareReported = shadow.reportedNextFirmware()?.version;
    const nextFirmwareDesired = shadow.desiredNextFirmware()?.version;

    const attributes = thingDocument.attributes;
    const groups = thingDocument.attributes?.customGroups;

    return new ThingListDisplay(
      name,
      groups,
      connectionTime,
      connectionTimeStr,
      currentFirmwware,
      firmwareDetails,
      nextFirmwareReported,
      nextFirmwareDesired,
      ThingAttributes.fromAttributes(attributes),
    );
  }

  static fromThingData(thing: ThingData): ThingListDisplay {
    return new ThingListDisplay(
      thing.thingName,
      thing.attributes?.customGroups,
    );
  }
}
