import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { StoreService } from './lib/store.service';
import { map } from 'rxjs/operators';
import { AuthorizationService } from './api/authorization.service';
import { FeatureGroupEnum } from './shared/user-rights-management/feature-group.enum';
import { Observable } from 'rxjs';

export function roleAuthGuard(requiredGroup: FeatureGroupEnum): CanActivateFn {
  return () => RoleAuthGuardUtils.verifyAuth(requiredGroup);
}

export class RoleAuthGuardUtils {
  static verifyAuth(
    requiredGroup: FeatureGroupEnum,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const storeService = inject(StoreService);
    const router = inject(Router);

    const canGoToRoute: () => boolean | UrlTree = () =>
      storeService.userHasGroup(requiredGroup) || router.parseUrl('/403');

    if (storeService.isInitialized()) {
      return canGoToRoute();
    } else {
      inject(AuthorizationService).initSession();
      return storeService.getInitEventEmitter().pipe(map(canGoToRoute));
    }
  }
}
