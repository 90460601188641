import {
  Attributes,
  DescribeThingResponse,
  DynamicGroupStatus,
  ThingArn,
  ThingDocument,
} from 'aws-sdk/clients/iot';
import CONFIG from '../../config';
import { ThingListDisplay } from './thing-list-display.model';

export type ThingType = string & { _: 'ThingType' };
export type MacAddress = string & { _: 'MacAddress' };
export type SerialNumber = string & { _: 'SerialNumber' };

export class ThingData {
  public thingType: ThingType;
  public serialNumber: SerialNumber;
  public thingName: string;
  public thingArn?: ThingArn;
  public attributes?: ThingAttributes;

  constructor(thingType: ThingType, serialNumber: SerialNumber) {
    this.thingType = thingType;
    this.serialNumber = serialNumber;
    this.thingName = `${thingType}-${serialNumber}`;
  }

  public static from(thingName: string): ThingData {
    const splits = thingName.split(/-(.+)/);
    const thingType = splits[0] as ThingType;
    const serialNumber = splits[1] as SerialNumber;
    return new ThingData(thingType, serialNumber);
  }

  public static fromDescribeResponse(thing: DescribeThingResponse): ThingData {
    const thingData = ThingData.from(thing.thingName as string);
    thingData.thingArn = thing.thingArn;
    thingData.attributes = ThingAttributes.fromAttributes(thing.attributes);
    return thingData;
  }

  /**
   * Creates a *simple* "Thing" representation from the result of an index search
   * It ignores information like the Shadow to keep the object simple and relatively lightweight
   *
   * @param thing The ThingDocument to get the values from
   */
  public static fromIndexSearch(thing: ThingDocument): ThingData {
    const thingData = ThingData.from(thing.thingName as string);
    thingData.thingArn = `${CONFIG.thingArnPrefix}${thingData.thingName}`;
    thingData.attributes = ThingAttributes.fromAttributes(thing.attributes);
    return thingData;
  }

  public static fromThingListDisplay(thing: ThingListDisplay): ThingData {
    const thingData = ThingData.from(thing.name);
    thingData.thingArn = `${CONFIG.thingArnPrefix}${thingData.thingName}`;
    thingData.attributes = thing.attributes;
    return thingData;
  }
}

export class ThingGroup {
  public thingGroupName: string;
  public thingGroupArn: string;
  public criteriaKey?: string;
  public status: DynamicGroupStatus;

  constructor(
    thingGroupName: string,
    thingGroupArn?: string,
    criteriaKey?: string,
    status?: DynamicGroupStatus,
  ) {
    this.thingGroupName = thingGroupName;
    this.thingGroupArn = thingGroupArn ?? '';
    this.criteriaKey = criteriaKey || undefined;
    this.status = status ?? '';
  }
}

export class ThingAttributes {
  constructor(
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public CMMF: string,
    public range: string,
    public technicalIncrement: string,
    public brand: string,
    public area: string,
    public customGroups: string,
  ) {}

  public static fromAttributes(attr?: Attributes): ThingAttributes | undefined {
    if (!attr) {
      return;
    }

    return new ThingAttributes(
      attr.CMMF,
      attr.range,
      attr.technicalIncrement,
      attr.brand,
      attr.area,
      attr.customGroups,
    );
  }
}
