import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AwsService } from '../lib/aws.service';
import { GetStatisticsResponse } from 'aws-sdk/clients/iot';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IotStatisticsService {
  constructor(private aws: AwsService) {}

  getThingCount(params: {
    thingType?: string;
    connected?: boolean;
  }): Observable<number> {
    return from(
      this.aws
        .iot()
        .getStatistics({
          queryString: this.buildQueryString(params),
        })
        .promise(),
    ).pipe(
      map((response: GetStatisticsResponse) => response.statistics?.count ?? 0),
    );
  }

  private buildQueryString(params: {
    thingType?: string;
    connected?: boolean;
  }): string {
    const queryChunks: string[] = [];

    if (params.connected != null) {
      queryChunks.push(`connectivity.connected:${params.connected}`);
    }

    if (params.thingType) {
      queryChunks.push(`thingTypeName:${params.thingType}`);
    }

    return queryChunks.join(' AND ');
  }
}
