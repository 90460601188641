import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { MetaversionService } from '../api/backend/services/metaversion/metaversion.service';
import { DatabaseService } from '../api/database.service';
import { ThingTypesService } from '../api/thing-types.service';
import { FirmwareFileService } from '../api/backend/services/firmware-file/firmware-file.service';
import { StoreService } from '../lib/store.service';
import { BrandArea, BrandAreaId, BrandAreaKey } from '../models/brandarea';
import {
  Firmware,
  FirmwareFileWrapper,
  IndiceKey,
  NewS3Object,
  RangeKey,
} from '../models/firmware';
import { MetaVersion } from '../models/metaversion/meta-version';
import { PRIORITY } from '../models/priority';
import { ThingType } from '../models/thingtype';
import { ThingTypesSelect } from '../models/thingtypesselect';
import { VALIDATION_ACCEPTED } from '../models/validation-accepted';
import { NotificationService } from '../shared/notification.service';

@Component({
  selector: 'app-new-firmware',
  templateUrl: './new-firmware.component.html',
  styleUrls: ['./new-firmware.component.scss'],
})
export class NewFirmwareComponent implements OnInit {
  @ViewChild('fileInputWifi') uploadInputsWifi?: ElementRef;
  @ViewChild('fileInputUi') uploadInputsUi?: ElementRef;
  @ViewChild('indiceInput') indiceInput?: ElementRef;
  @ViewChild('uiVersionInput') uiVersionInput?: ElementRef;

  filters: ThingTypesSelect[] = [{ id: '1', name: 'Thing Type' }];
  filtersPriority: string[] = PRIORITY.all;
  filtersValidationAccepted: string[] = VALIDATION_ACCEPTED.all;
  firmwares: Firmware[] = [];
  firmwaresWifi: Firmware[] = [];
  firmwaresUi: Firmware[] = [];
  brandAreas: BrandArea[] = [];
  brandAreasWifi: BrandArea[] = [];
  brandAreaIdWifi?: BrandAreaId;
  brandAreasValueWIFI?: BrandArea;
  brandAreasUi: BrandArea[] = [];
  brandAreaIdUi?: BrandAreaId;
  brandAreasValueUI?: BrandArea;
  thingName?: string;
  filterValue: string = this.filters[0].name;
  thingType?: string;
  isLoading = false;
  metaVersion = MetaVersion.empty();

  additionalCmmfs: string[] = [];

  extractingFirmwareData = false;
  firmwareTemporaryFilename?: string;

  constructor(
    private notif: NotificationService,
    private router: Router,
    private dataBaseService: DatabaseService,
    private firmwareFileService: FirmwareFileService,
    private thingTypesService: ThingTypesService,
    private storeService: StoreService,
    private metaversionService: MetaversionService,
  ) {}

  ngOnInit(): void {
    this.getThingTypes();
  }

  async onChangeTypeThing(event: Event): Promise<void> {
    this.isLoading = true;
    const selectedThingType = (event.target as HTMLOptionElement)
      .value as ThingType;

    this.filterValue = selectedThingType;
    if (selectedThingType === 'Thing Type') {
      this.thingType = '';
      this.metaVersion.thingType = '' as ThingType;
    } else {
      this.thingType = this.filterValue;
      this.metaVersion.thingType = selectedThingType;
      if (this.metaVersion?.uiFirmware?.newS3Key) {
        // If "New UI Firmware" is selected ("newS3Key" exists only under this condition)
        this.metaVersion.uiFirmware.thingType = selectedThingType;
      }
      if (this.metaVersion?.wifiFirmware?.newS3Key) {
        // If "New WIFI Firmware" is selected ("newS3Key" exists only under this condition)
        this.metaVersion.wifiFirmware.thingType = selectedThingType;
      }

      this.brandAreas = (await this.storeService.getBrandAreas()).filter(
        (_) => _.thingType === this.metaVersion.thingType,
      );
      // WIFI
      this.brandAreaIdWifi =
        `${this.metaVersion.thingType}_wifi` as BrandAreaId;
      this.brandAreasWifi = this.brandAreas.filter(
        (_) => _.id === this.brandAreaIdWifi,
      );
      this.brandAreasValueWIFI = this.brandAreas.find(
        (_) => _.id === `${this.metaVersion.thingType}_wifi`,
      );
      // UI
      this.brandAreaIdUi = `${this.metaVersion.thingType}_ui` as BrandAreaId;
      this.brandAreasUi = this.brandAreas.filter(
        (_) => _.id === this.brandAreaIdUi,
      );
      this.brandAreasValueUI = this.brandAreas.find(
        (_) => _.id === `${this.metaVersion.thingType}_ui`,
      );
      //
      await this.refreshFirmwares();
    }
    this.isLoading = false;
  }

  onChangeRange(e: Event): void {
    if (!this.metaVersion.uiFirmware?.newS3Key) {
      return;
    }
    this.metaVersion.uiFirmware.newS3Key.range =
      ((e.target as HTMLOptionElement).value.trim() as RangeKey) || undefined;
  }

  /**
   * Adds a CMMF either into the firmware, or to the "additional cmmfs" array
   *
   * @param firmware the firmware to add the CMMF to
   * @param cmmf the added CMMF
   */
  onAddingCMMF(firmware: Firmware, cmmf: string): void {
    this.firmwareFileService.helpHandlingAddingCMMF(
      firmware,
      cmmf,
      this.additionalCmmfs,
    );
  }

  /**
   * Removing a CMMF will either remove it from the firmware or remove it from the "additional cmmfs" array
   * If the removed CMMF is from the firmware, but additional CMMFs exist in the "additional cmmf",
   * then the first element is removed to be set in the Firmware
   *
   * @param firmware The firmware which CMMF to handle (Should be uiFirmware)
   * @param removedCmmf the removed CMMF
   */
  onRemovingCMMF(firmware: Firmware, removedCmmf: string): void {
    this.firmwareFileService.helpHandlingRemovingCMMF(
      firmware,
      removedCmmf,
      this.additionalCmmfs,
    );
  }

  onChangeIndice(e: Event): void {
    if (!this.metaVersion.uiFirmware?.newS3Key) {
      return;
    }
    this.metaVersion.uiFirmware.newS3Key.indice =
      ((e.target as HTMLOptionElement).value.trim() as IndiceKey) || undefined;
  }

  onChangeIdMetaVersion(e: Event): void {
    this.metaVersion.id =
      (e.target as HTMLInputElement).value.trim() || undefined;
  }

  onChangeValidationAccepted(e: Event): void {
    const value = (e.target as HTMLOptionElement).value;
    this.metaVersion.validationAccepted = value.length > 0 ? value : undefined;
  }

  onChangePriority(e: Event): void {
    const value = (e.target as HTMLOptionElement).value;
    this.metaVersion.priority = value.length > 0 ? value : undefined;
  }

  onChangeUpdateValidation(): void {
    this.metaVersion.updateValidation = !this.metaVersion.updateValidation;
  }

  onChangeVersionWifi(event: Event): void {
    if (this?.metaVersion?.wifiFirmware) {
      this.metaVersion.wifiFirmware.version = (
        event.target as HTMLInputElement
      )?.value?.trim();
    }
  }

  onChangeVersionUi(event: Event): void {
    if (this?.metaVersion?.uiFirmware) {
      this.metaVersion.uiFirmware.version = (
        event.target as HTMLInputElement
      )?.value?.trim();
    }
  }

  onChangeFirmwareSignedWifi(event: Event): void {
    if (this?.metaVersion?.wifiFirmware?.newS3Key) {
      this.metaVersion.wifiFirmware.newS3Key.isSigned = (
        event.target as HTMLInputElement
      ).checked;
      this.metaVersion.wifiFirmware.newS3Key.isAlreadySigned = (
        event.target as HTMLInputElement
      ).checked;
    }
  }

  onChangeFirmwareSignedUi(event: Event): void {
    if (this?.metaVersion?.uiFirmware?.newS3Key) {
      this.metaVersion.uiFirmware.newS3Key.isSigned = (
        event.target as HTMLInputElement
      ).checked;
      this.metaVersion.uiFirmware.newS3Key.isAlreadySigned = (
        event.target as HTMLInputElement
      ).checked;
    }
  }

  onChangeBootloaderWifi(event: Event): void {
    if (this?.metaVersion?.wifiFirmware?.newS3Key) {
      this.metaVersion.wifiFirmware.newS3Key.bootloader = (
        event.target as HTMLInputElement
      ).value;
    }
  }

  onChangeBootloaderUi(event: Event): void {
    if (this?.metaVersion?.uiFirmware?.newS3Key) {
      this.metaVersion.uiFirmware.newS3Key.bootloader = (
        event.target as HTMLInputElement
      ).value;
    }
  }

  onChangeReleaseNoteWifi(event: Event): void {
    if (this?.metaVersion?.wifiFirmware) {
      this.metaVersion.wifiFirmware.releaseNote = (
        event.target as HTMLTextAreaElement
      ).value;
    }
  }

  onChangeReleaseNoteUi(event: Event): void {
    if (this?.metaVersion?.uiFirmware) {
      this.metaVersion.uiFirmware.releaseNote = (
        event.target as HTMLTextAreaElement
      ).value;
    }
  }

  onChangeBrandAreaWifi(e: Event): void {
    const value = (e.target as HTMLOptionElement).value as BrandAreaKey;
    if (this?.metaVersion?.wifiFirmware?.newS3Key) {
      this.metaVersion.wifiFirmware.newS3Key.brandArea =
        value === '' ? ('NA' as BrandAreaKey) : value;
    }
  }

  onChangeBrandAreaUi(e: Event): void {
    const value = (e.target as HTMLOptionElement).value as BrandAreaKey;
    if (this?.metaVersion?.uiFirmware?.newS3Key) {
      this.metaVersion.uiFirmware.newS3Key.brandArea =
        value === '' ? ('NA' as BrandAreaKey) : value;
    }
  }

  onClickCancel(): void {
    this.router.navigateByUrl('/metaversions');
  }

  async onChangeWIFIFirmware(event: Event): Promise<void> {
    const metaVersion = this.metaVersion;

    const value = (event.target as HTMLOptionElement).value;

    switch (value) {
      case 'None':
        metaVersion.wifiFirmware = undefined;
        break;
      case 'New WIFI Firmware':
        metaVersion.wifiFirmware = Firmware.empty();
        metaVersion.wifiFirmware.type = 'wifi';
        metaVersion.wifiFirmware.thingType = this.metaVersion.thingType;
        break;
      default:
        const firmware = this.firmwares.find(
          (_) => _.version === value && _.type === 'wifi',
        );
        if (!firmware) {
          return;
        }
        metaVersion.wifiFirmware = firmware;
    }
    this.metaVersion = metaVersion;
  }

  async onChangeUIFirmware(event: Event): Promise<void> {
    const metaVersion = this.metaVersion;

    const value = (event.target as HTMLOptionElement).value;

    switch (value) {
      case 'None':
        metaVersion.uiFirmware = undefined;
        break;
      case 'New UI Firmware':
        metaVersion.uiFirmware = Firmware.empty();
        metaVersion.uiFirmware.type = 'ui';
        metaVersion.uiFirmware.thingType = this.metaVersion.thingType;
        break;
      default:
        const firmware = this.firmwares.find(
          (_) => _.version === value && _.type === 'ui',
        );
        if (!firmware) {
          return;
        }
        metaVersion.uiFirmware = firmware;
    }
    this.metaVersion = metaVersion;
  }

  extractFirmwareData(): void {
    const file = this.uploadInputsUi?.nativeElement.files?.[0];
    if (!file) {
      return;
    }
    this.extractingFirmwareData = true;
    const sourceFilename = file.name;

    this.firmwareFileService.extractFirmwareData(file).subscribe(
      ({ tempFilename, extractedData }) => {
        console.log(
          'Extracted data from firmware',
          sourceFilename,
          tempFilename,
          extractedData,
        );
        this.firmwareTemporaryFilename = tempFilename;

        if (this.firmwareFileService.hasExtractedData(extractedData)) {
          if (
            this.indiceInput &&
            extractedData.technicalIncrement !== undefined &&
            extractedData.technicalIncrement >= 0
          ) {
            this.indiceInput.nativeElement.value =
              extractedData.technicalIncrement;
            this.indiceInput.nativeElement.dispatchEvent(new Event('change'));
          }
          if (
            this.uiVersionInput &&
            extractedData.version != null &&
            extractedData.version.length > 0
          ) {
            this.uiVersionInput.nativeElement.value = extractedData.version;
            this.uiVersionInput.nativeElement.dispatchEvent(
              new Event('change'),
            );
          }
        } else {
          this.notif.showInfo(
            'No data could be extracted from the given Firmware file',
          );
        }

        this.extractingFirmwareData = false;
      },
      (error) => {
        console.error(error);
        this.notif.showWarning(
          `Couldn't extract data from Firmware file : ${error?.message}`,
        );
        this.extractingFirmwareData = false;
      },
    );
  }

  async createFirmware(): Promise<void> {
    if (this.isLoading) {
      return;
    }

    if (
      this.metaVersion.thingType.length === 0 ||
      !this.metaVersion.id?.length
    ) {
      this.notif.showError('Invalid parameters!');
      return;
    }

    if (
      (this.metaVersion.uiFirmware &&
        this.metaVersion.uiFirmware.version.length === 0) ||
      (this.metaVersion.wifiFirmware &&
        this.metaVersion.wifiFirmware.version.length === 0)
    ) {
      this.notif.showError('Firmware version must be provided');
      return;
    }

    if (!this.metaVersion.wifiFirmware && !this.metaVersion.uiFirmware) {
      this.notif.showError('You need at least one firmware file!');
      return;
    }

    const wifiS3Key = this.metaVersion?.wifiFirmware?.newS3Key;
    const uiS3Key = this.metaVersion?.uiFirmware?.newS3Key;

    if (
      uiS3Key?.range?.includes('_') ||
      uiS3Key?.cmmf?.includes('_') ||
      uiS3Key?.indice?.includes('_')
    ) {
      this.notif.showError(
        'Refrain from using the character "_" in range, CMMF or indice',
      );
      return;
    }

    if (uiS3Key?.range && uiS3Key?.cmmf) {
      this.notif.showError('You can not set both range and CMMF');
      return;
    }

    if (!uiS3Key?.cmmf && !uiS3Key?.range && uiS3Key?.indice) {
      this.notif.showError('You can not set indice without a CMMF or a range!');
      return;
    }

    const brandAreaMandatoryForUi = this.brandAreas.find(
      (_) => _.id === `${this.metaVersion.thingType}_ui`,
    );
    const brandAreaMandatoryForWifi = this.brandAreas.find(
      (_) => _.id === `${this.metaVersion.thingType}_wifi`,
    );

    if (
      uiS3Key &&
      brandAreaMandatoryForUi &&
      (!uiS3Key.brandArea || uiS3Key.brandArea === 'NA')
    ) {
      this.notif.showError('Brand Area is missing for UI firmware');
      return;
    }
    if (
      wifiS3Key &&
      brandAreaMandatoryForWifi &&
      (!wifiS3Key.brandArea || wifiS3Key.brandArea === 'NA')
    ) {
      this.notif.showError('Brand Area is missing for WIFI firmware');
      return;
    }

    if (uiS3Key?.cmmf && !FirmwareFileService.isCMMFValid(uiS3Key?.cmmf)) {
      this.notif.showError(`CMMF "${uiS3Key?.cmmf}" should be 10 digits only`);
      return;
    }

    if (this.additionalCmmfs?.length) {
      let invalidCMMFs = false;
      for (const additionalCmmf of this.additionalCmmfs) {
        if (!FirmwareFileService.isCMMFValid(additionalCmmf)) {
          invalidCMMFs = true;
          this.notif.showError(
            `CMMF "${additionalCmmf}" should be 10 digits only`,
          );
        }
      }

      if (invalidCMMFs) {
        return;
      }
    }

    if (uiS3Key && this.metaVersion.uiFirmware?.newS3Key) {
      this.metaVersion.uiFirmware.criteriaType =
        this.metaVersion.uiFirmware.newS3Key.getCriteriaType();
    }

    this.isLoading = true;

    try {
      const firmwares$ = this.uploadFirmwares(wifiS3Key, uiS3Key);
      combineLatest(firmwares$.length ? firmwares$ : [of([])])
        .pipe(
          switchMap(() =>
            this.metaversionService.createMetaversion(this.metaVersion),
          ),
        )
        .subscribe(
          () => {
            this.notif.showSuccess('Metaversion created');
            this.router.navigateByUrl('/metaversions');
          },
          (e) => {
            console.error(e);
            this.notif.showError(e.message, e);
            this.refreshFirmwares();
            this.isLoading = false;
          },
        );
    } catch (e) {
      console.error(e);
      this.refreshFirmwares();
      this.notif.showError((e as Error).message, e);
      this.isLoading = false;
    }
  }

  private uploadFirmwares(
    wifiS3Key: undefined | NewS3Object,
    uiS3Key: undefined | NewS3Object,
  ): Observable<string>[] {
    const firmwares$: Observable<string>[] = [];

    if (
      wifiS3Key &&
      this.metaVersion.wifiFirmware &&
      this.uploadInputsWifi?.nativeElement?.files?.[0]
    ) {
      const wifiFile = this.uploadInputsWifi.nativeElement.files[0];
      const firmwareFileWrapper: FirmwareFileWrapper = {
        file: wifiFile,
        filename: wifiFile.name,
      };

      firmwares$.push(
        this.firmwareFileService
          .createFirmwareAndHandleToast(
            this.metaVersion.wifiFirmware,
            firmwareFileWrapper,
          )
          .pipe(
            tap(
              (createdId) =>
                ((this.metaVersion.wifiFirmware as Firmware).id = createdId),
            ),
          ),
      );
    }

    if (
      uiS3Key &&
      this.metaVersion.uiFirmware &&
      this.uploadInputsUi?.nativeElement?.files?.[0]
    ) {
      const firmwareFileWrapper: FirmwareFileWrapper = {
        sourceFilename: this.firmwareTemporaryFilename,
      };
      firmwares$.push(
        this.firmwareFileService
          .createFirmwareAndHandleToast(
            this.metaVersion.uiFirmware,
            firmwareFileWrapper,
            this.additionalCmmfs,
          )
          .pipe(
            tap(
              (createdId) =>
                ((this.metaVersion.uiFirmware as Firmware).id = createdId),
            ),
          ),
      );
    }

    return firmwares$;
  }

  private getThingTypes(): void {
    this.thingTypesService
      .getThingTypes()
      ?.pipe(
        catchError((err) => {
          this.notif.showError(err.message, err);
          return of([]);
        }),
      )
      .subscribe((thingTypes) => {
        let index = 2;
        for (const type of thingTypes) {
          this.filters.push({ id: index.toString(), name: type });
          index++;
        }
      });
  }

  private async fetchFirmwares(thingType: string): Promise<void> {
    // Get firmwares
    this.firmwares = await this.dataBaseService
      .listFirmwares(1, thingType)
      .then();
    this.firmwaresWifi = this.firmwares?.filter((_) => _.type === 'wifi');
    this.firmwaresUi = this.firmwares?.filter((_) => _.type === 'ui');
  }

  private refreshFirmwares(): Promise<void> {
    return this.fetchFirmwares(this.metaVersion.thingType);
  }
}
