import { DocumentClient } from 'aws-sdk/clients/dynamodb';

export type BrandAreaId = string & { _: 'BrandAreaId' };
export type BrandAreaKey = string & { _: 'BrandAreaKey' };

export class BrandArea {
  public thingType: string;
  public firmwareType: string;
  public key: BrandAreaKey;
  public id: BrandAreaId;
  public description: string;

  constructor(
    thingType: string,
    firmwareType: string,
    id: BrandAreaId,
    key: BrandAreaKey,
    description: string,
  ) {
    this.thingType = thingType;
    this.firmwareType = firmwareType;
    this.id = id;
    this.key = key;
    this.description = description;
  }

  public static fromItemList(itemList: DocumentClient.ItemList): BrandArea[] {
    const brandAreas: BrandArea[] = [];

    itemList.forEach((item) => {
      item.brandArea.forEach(
        (brandArea: {
          brand: { key: string; description: string };
          area: { key: string; description: string; countries: unknown[] }[];
          key: string;
          description: string;
        }) => {
          brandArea.area.forEach((area) => {
            brandAreas.push(
              new BrandArea(
                item.thingType,
                item.firmwareType,
                `${item.thingType}_${item.firmwareType}` as BrandAreaId,
                `${brandArea.brand.key}.${area.key}` as BrandAreaKey,
                `${brandArea.brand.description ? brandArea.brand.description : ''}-${area.description ? area.description : ''}`,
              ),
            );
          });
        },
      );
    });

    return brandAreas.sort((a, b) => (a.key > b.key ? 1 : -1));
  }
}
