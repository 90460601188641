import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { filter, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { RouteTitleData, TitleService } from './shared/title.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'adminConsoleV2';
  showNavBarSideBar$: Observable<boolean>;

  constructor(
    private location: Location,
    private titleService: TitleService,
    private readonly activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.showNavBarSideBar$ = this.router.events.pipe(
      map(
        () =>
          !(
            this.location.path() === '/login' ||
            this.location.path().match(/\/loading(.)+/)
          ),
      ),
    );
  }

  ngOnInit(): void {
    const mainTitle = this.titleService.getTitle();
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        switchMap(() => {
          const routeFirstChild = this.getRouteFirstChild();
          const routeData = routeFirstChild?.snapshot?.data as RouteTitleData;

          if (routeData?.title && !routeData?.titleRouteParam) {
            return of(routeData.title);
          } else if (routeData?.titleRouteParam) {
            return (
              routeFirstChild?.paramMap?.pipe(
                map((paramMap) => {
                  const routeTitle = routeData.title;
                  const pathValue = paramMap.get(
                    routeData.titleRouteParam as string,
                  );
                  return routeTitle
                    ? `${routeTitle} - ${pathValue}`
                    : pathValue;
                }),
              ) ?? of(null)
            );
          }
          return of(null);
        }),
      )
      .subscribe((customTitle: string | null) => {
        if (customTitle) {
          this.titleService.setTitle(`${mainTitle} | ${customTitle}`);
        }
      });
  }

  getRouteFirstChild(): ActivatedRoute | null | undefined {
    return this.activatedRoute?.firstChild?.firstChild;
  }
}
