import { StoreService } from '../lib/store.service';
import { Injectable } from '@angular/core';
import * as Auth from 'aws-amplify/auth';
import * as AWS from 'aws-sdk';
import CONFIG from '../../config';
import { AwsService } from '../lib/aws.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  private initialized = false;

  constructor(
    private aws: AwsService,
    private storeService: StoreService,
  ) {}

  async initSession(): Promise<void> {
    if (this.initialized) {
      return Promise.resolve();
    }

    const session = await Auth.fetchAuthSession();

    if (session != null && session.tokens != null) {
      return this.handleLoginSuccess(session);
    }

    return Promise.reject('No cognito session');
  }

  async validateUserSession(): Promise<Auth.AuthSession> {
    // Get the current user session
    const session = await Auth.fetchAuthSession();

    await this.handleLoginSuccess(session);
    return session;
  }

  async handleLoginSuccess(session: Auth.AuthSession): Promise<void> {
    return new Promise((resolve, reject) => {
      // Parsing Cognito session
      const credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: CONFIG.cognitoIdentityPoolId,
        Logins: {
          [`cognito-idp.${CONFIG.awsRegion}.amazonaws.com/${CONFIG.cognitoUserPoolId}`]:
            session.tokens!.idToken!.toString(),
        },
      });
      // Setting credentials globally
      AWS.config.region = CONFIG.awsRegion;
      AWS.config.credentials = credentials;
      // Refreshing credentials
      credentials.refresh((err) => {
        if (err) {
          return reject(err);
        }
        // Attaching IoT policy to user
        this.aws
          .iot()
          .attachPolicy({
            policyName: CONFIG.iotPolicyName,
            target: credentials.identityId,
          })
          .promise()
          .then((_) => {
            // Refreshing credentials
            if (
              !AWS.config.credentials ||
              !(AWS.config.credentials instanceof AWS.Credentials)
            ) {
              return reject(new Error('AWS Credentials are not usable'));
            }
            AWS.config.credentials.refresh((err2) => {
              if (err2) {
                return reject(err2);
              }
              // Starting application
              this.storeService.initStore(session);
              resolve();
            });
          }, reject);
      });
    });
  }

  async signIn(): Promise<void> {
    await Auth.signInWithRedirect({
      provider: {
        custom: CONFIG.cognitoIdpName,
      },
    });
  }
}
