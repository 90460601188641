import { AwsService } from '../lib/aws.service';
import { Injectable } from '@angular/core';
import CONFIG from '../../config';
import { from, Observable } from 'rxjs';
import { CopyObjectOutput } from 'aws-sdk/clients/s3';
import { S3ProxyInput, S3ProxyInputPresignMode } from '../models/S3ProxyInput';
import { switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(
    private readonly awsService: AwsService,
    private readonly http: HttpClient,
  ) {}

  getSignedUrl(s3key: string, bucket: string): string {
    return this.awsService.s3().getSignedUrl('getObject', {
      Bucket: bucket,
      Key: s3key,
    });
  }

  uploadFirmwareFile(
    file: File,
    s3Key: string,
    bucket: string,
  ): Observable<unknown> {
    return from(
      this.invokeProxy<string>(
        'PutObjectCommand',
        {
          ACL: 'bucket-owner-full-control',
          Bucket: bucket,
          Key: s3Key,
        },
        CONFIG.s3ProxyWriteLambda,
        {
          active: true,
          expiration: 10,
        },
      ),
    ).pipe(switchMap((presignedUrl) => this.http.put(presignedUrl, file)));
  }

  uploadTempFile(file: File, s3Key: string): Observable<unknown> {
    return this.uploadFirmwareFile(
      file,
      s3Key,
      CONFIG.temporaryFirmwaresBucket,
    );
  }

  copyFile(
    sourceS3Key: string,
    targetS3Key: string,
    sourceBucket: string,
    targetBucket: string,
  ): Observable<CopyObjectOutput> {
    return from(
      this.invokeProxy<CopyObjectOutput>(
        'CopyObjectCommand',
        {
          Bucket: targetBucket,
          ACL: 'bucket-owner-full-control',
          CopySource: '/' + sourceBucket + '/' + sourceS3Key,
          Key: targetS3Key,
        },
        CONFIG.s3ProxyWriteLambda,
      ),
    );
  }

  private async invokeProxy<T>(
    commandType: string,
    commandInput: unknown,
    proxyName: string,
    presignMode?: S3ProxyInputPresignMode,
  ): Promise<T> {
    const s3ProxyInput: S3ProxyInput = {
      command: {
        type: commandType,
        input: commandInput,
      },
      presignMode,
    };

    const proxyResult = await this.awsService
      .lambda()
      .invoke({
        FunctionName: proxyName,
        Payload: new TextEncoder().encode(JSON.stringify(s3ProxyInput)),
      })
      .promise();

    if (!proxyResult.Payload) {
      throw new Error(`Error proxying security S3 request: ${proxyResult}`);
    }

    const decodedResult: { status: number; body: T } = JSON.parse(
      proxyResult.Payload.toString(),
    );

    if (decodedResult.status < 200 || decodedResult.status >= 300) {
      throw new Error(
        `Error proxying security S3 request: ${decodedResult.body}`,
      );
    }

    return decodedResult.body;
  }
}
