import { Iot } from 'aws-sdk';
import * as moment from 'moment';
import { JobType } from './meta-version-job.model';
import { ThingType } from './thingtype';

export class Job {
  public jobId: string;
  public status: string;
  public targetSelection: string;
  public thingGroupId: string;
  public createdAt?: Date;
  public createdAtStr: string;
  public lastUpdatedAt?: Date;
  public completedAt?: Date;
  public jobType: string;
  public thingType: ThingType;
  public metaversionId?: string;
  public singleOrMulti?: JobType;
  public createdBy?: string;

  constructor(
    jobId: string,
    status: string,
    targetSelection: string,
    thingGroupId: string,
    createdAt?: Date,
    lastUpdatedAt?: Date,
    completedAt?: Date,
    createdBy?: string,
  ) {
    this.jobId = jobId;
    this.status = status;
    this.targetSelection = targetSelection;
    this.thingGroupId = thingGroupId;
    this.createdAt = createdAt;
    this.createdAtStr = moment(createdAt).format('YYYY-MM-DD HH:mm:ss');
    this.lastUpdatedAt = lastUpdatedAt;
    this.completedAt = completedAt;
    this.createdBy = createdBy;

    const parts = this.jobId.split('_');
    this.jobType = parts[0] as JobType;
    this.thingType = parts[1] as ThingType;
  }

  public static fromItemList(jobList: Iot.JobSummaryList): Job[] {
    return jobList.map(
      (job) =>
        new Job(
          job.jobId ?? '',
          job.status ?? '',
          job.targetSelection ?? '',
          job.thingGroupId ?? '',
          job.createdAt,
          job.lastUpdatedAt,
          job.completedAt,
        ),
    );
  }

  public addMetaversionId(metaversionId: string): Job {
    this.metaversionId = metaversionId;
    return this;
  }

  public addCreatedBy(createdBy?: string): Job {
    this.createdBy = createdBy;
    return this;
  }

  public addSingleOrMulti(type: JobType): Job {
    this.singleOrMulti = type;
    return this;
  }
}
