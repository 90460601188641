import { DynamoDB, S3, Iot, IotData, CostExplorer, Lambda } from 'aws-sdk';
import { Injectable } from '@angular/core';
import CONFIG from '../../config';

@Injectable({
  providedIn: 'root',
})
export class AwsService {
  dynamodbClient?: DynamoDB.DocumentClient;
  s3Client?: S3;
  iotClient?: Iot;
  iotDataClient?: IotData;
  costExplorerClient?: CostExplorer;
  lambdaClient?: Lambda;

  dynamodb(): DynamoDB.DocumentClient {
    if (!this.dynamodbClient) {
      this.dynamodbClient = new DynamoDB.DocumentClient();
    }
    return this.dynamodbClient;
  }

  s3(): S3 {
    if (!this.s3Client) {
      this.s3Client = new S3();
    }
    return this.s3Client;
  }

  iot(): Iot {
    if (!this.iotClient) {
      this.iotClient = new Iot();
    }
    return this.iotClient;
  }

  iotData(): IotData {
    if (!this.iotDataClient) {
      this.iotDataClient = new IotData({
        endpoint: CONFIG.iotEndpoint,
      });
    }
    return this.iotDataClient;
  }

  costExplorer(): CostExplorer {
    if (!this.costExplorerClient) {
      this.costExplorerClient = new CostExplorer({
        region: 'us-east-1',
      });
    }
    return this.costExplorerClient;
  }

  lambda(): Lambda {
    if (!this.lambdaClient) {
      this.lambdaClient = new Lambda();
    }

    return this.lambdaClient;
  }
}
