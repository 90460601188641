import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditAction, AuditType } from '@common/audit-log/models/AuditLog';
import {
  Job,
  JobDocument,
  JobExecutionSummaryForJobList,
  NextToken,
} from 'aws-sdk/clients/iot';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuditService } from '../api/backend/services/audit/audit.service';
import { JobsService } from '../api/jobs.service';
import { GroupOfThings } from '../models/Group-of-things.model';
import { NotificationService } from '../shared/notification.service';

@Component({
  selector: 'app-deployment',
  templateUrl: './deployment.component.html',
  styleUrls: ['./deployment.component.scss'],
})
export class DeploymentComponent implements OnInit {
  job?: Job;
  jobId?: string | null;
  jobDocument?: JobDocument;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  parsedDocument: any;
  devices?: JobExecutionSummaryForJobList;
  sumAll = 0;
  sumDone = 0;
  sumQueued = 0;
  sumInProgress = 0;
  sumSuccess = 0;
  sumFail = 0;
  sumIgnored = 0;
  statusFilter = 'ALL';
  devicesStatus: string[] = [];
  nextToken?: NextToken;
  successProgress?: number;
  failProgress?: number;
  ignoredProgress?: number;
  statusFilters: string[] = ['ALL'];
  statusFilterValue = this.statusFilters[0];
  showDocument = false;

  item: unknown[] = [];

  group$?: Observable<GroupOfThings | string | null>;

  readonly AuditAction = AuditAction;
  readonly AuditType = AuditType;

  constructor(
    private activatedRoute: ActivatedRoute,
    private notif: NotificationService,
    private jobService: JobsService,
    private auditService: AuditService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    // getting params jobId
    this.activatedRoute.paramMap.subscribe(async (params) => {
      this.jobId = params.get('jobId');
      // getting the job data
      this.refresh().catch((err: Error) =>
        this.notif.showError(err.message, err),
      );
    });
  }

  async refreshJob(): Promise<void> {
    try {
      await this.refresh();
      this.notif.showSuccess('Job refreshed');
    } catch (e) {
      this.notif.showError((e as Error).message, e);
    }
  }

  async cancelJob(): Promise<void> {
    try {
      await this.jobService.cancelJob(this.job?.jobId || '');

      this.auditService.pushEvent({
        type: AuditType.DEPLOYMENT,
        action: AuditAction.STOP,
        resourceId: this.job?.jobId,
      });

      this.notif.showSuccess('Deployment canceled!');
    } catch (e) {
      this.notif.showError((e as Error).message, e);
    }
  }

  async getProduct(): Promise<void> {
    if (!this.jobId) {
      return;
    }

    const devicesForJob = await this.jobService.listDevicesForJob(
      this.jobId,
      this.statusFilterValue === 'ALL' ? undefined : this.statusFilterValue,
      this.nextToken,
    );

    const newDevices = devicesForJob.executionSummaries || [];
    const nextToken = devicesForJob.nextToken;

    this.devices = this.devices ? this.devices.concat(newDevices) : newDevices;
    this.nextToken = nextToken;
  }

  viewDevice(thingArn: string): void {
    const id = thingArn ? thingArn.split('/')[1] : '';
    this.router.navigateByUrl('/things/' + id);
  }

  async onChange(e: Event): Promise<void> {
    this.statusFilterValue = (e.target as HTMLOptionElement).value;
    this.devices = [];
    this.nextToken = undefined;
    await this.getProduct();
  }

  private async refresh(): Promise<void> {
    try {
      if (!this.jobId) {
        return;
      }

      const [job, jobDocument, devicesForJob] = await Promise.all([
        this.jobService.getJob(this.jobId),
        this.jobService.getJobDocument(this.jobId),
        this.jobService.listDevicesForJob(this.jobId),
      ]);

      // Default status
      const statusFilter = 'ALL';

      // Preparing sums
      let sumAll = 0;
      let sumDone = 0;
      let sumQueued = 0;
      let sumInProgress = 0;
      let sumSuccess = 0;
      let sumFail = 0;
      let sumIgnored = 0;

      const devicesStatus: string[] = [
        'QUEUED',
        'IN_PROGRESS',
        'SUCCEEDED',
        'FAILED',
        'TIMED_OUT',
        'REJECTED',
        'REMOVED',
        'CANCELED',
      ];
      const devices = devicesForJob.executionSummaries || [];
      const nextToken = devicesForJob.nextToken;

      const jobProcessDetails = job.jobProcessDetails;
      if (jobProcessDetails) {
        sumQueued = jobProcessDetails.numberOfQueuedThings || 0;
        sumInProgress = jobProcessDetails.numberOfInProgressThings || 0;
        sumSuccess = jobProcessDetails.numberOfSucceededThings || 0;
        sumFail =
          (jobProcessDetails.numberOfFailedThings || 0) +
          (jobProcessDetails.numberOfTimedOutThings || 0);
        sumIgnored =
          (jobProcessDetails.numberOfRejectedThings || 0) +
          (jobProcessDetails.numberOfRemovedThings || 0) +
          (jobProcessDetails.numberOfCanceledThings || 0);
        sumDone = sumSuccess + sumFail + sumIgnored;
        sumAll = sumQueued + sumInProgress + sumDone;
      }

      // Refresh UI
      this.job = job;
      this.jobDocument = jobDocument;
      try {
        this.parsedDocument = JSON.parse(jobDocument);
      } catch (e) {
        this.notif.showError('Job document not formated correctly', e);
        this.parsedDocument = jobDocument;
      }
      this.sumAll = sumAll;
      this.sumDone = sumDone;
      this.sumQueued = sumQueued;
      this.sumInProgress = sumInProgress;
      this.sumSuccess = sumSuccess;
      this.sumFail = sumFail;
      this.sumIgnored = sumIgnored;
      this.devicesStatus = devicesStatus;
      this.nextToken = nextToken;
      this.statusFilter = statusFilter;
      // filtering results
      this.devices = devices.filter(
        (device) =>
          this.statusFilter === 'ALL' ||
          device?.jobExecutionSummary?.status === this.statusFilter,
      );
      // preparing filter
      for (const status of devicesStatus) {
        this.statusFilters.push(status);
      }

      this.successProgress = Math.round((100 * this.sumSuccess) / this.sumAll);
      this.failProgress = Math.round((100 * this.sumFail) / this.sumAll);
      this.ignoredProgress = Math.round((100 * this.sumIgnored) / this.sumAll);

      this.group$ = this.jobService.getGroupForJob(this.jobId).pipe(
        catchError((err) => {
          this.notif.showError(
            `Error fetching group information : ${err.message ?? err}`,
            err,
          );
          return of(null);
        }),
      );
    } catch (e) {
      this.notif.showError((e as Error).message, e);
    }
  }
}
