import { SigV4UtilsService } from '../lib/sig-v4-utils.service';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import * as AWS from 'aws-sdk';
import { Credentials } from 'aws-sdk';
import { ClipboardService } from 'ngx-clipboard';
import { NotificationService } from '../shared/notification.service';
import CONFIG from '../../config';
import { ThingData } from '../models/thingtype';
import { Client, ErrorWithInvocationContext, Message, Qos } from 'paho-mqtt';
import * as uuid from 'uuid';
import { IotMessage } from '../models/iotmessage';
import {
  getCookingStatusThings,
  getStatusThings,
} from './constants/thing-types.constants';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-realtime',
  templateUrl: './realtime.component.html',
  styleUrls: ['./realtime.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RealtimeComponent implements OnInit, OnDestroy {
  private readonly TIMEOUT = 5;
  private readonly QOS: Qos = 1;

  @Input() thingName?: string;

  client?: Client;
  messages: WritableSignal<IotMessage[]> = signal([]);
  thingData?: ThingData;
  isPaused = false;

  constructor(
    private readonly clipboardService: ClipboardService,
    private readonly notificationService: NotificationService,
    private readonly sigV4UtilsService: SigV4UtilsService,
  ) {}

  ngOnInit(): void {
    if (this.thingName) {
      this.thingData = ThingData.from(this.thingName);
    }

    if (!(AWS.config.credentials instanceof AWS.Credentials)) {
      this.notificationService.showError('Missing AWS credentials!');
      return;
    }

    this.client = this.getClient(AWS.config.credentials);

    if (this.client) {
      this.client.onMessageArrived = this.onMessageArrived.bind(this);
      this.client.onConnectionLost = this.onConnectionLost.bind(this);
      this.client.connect({
        useSSL: true,
        timeout: this.TIMEOUT,
        mqttVersion: 4,
        onSuccess: this.onConnectionSuccess.bind(this),
        onFailure: this.onConnectionFailure.bind(this),
      });
    }
  }

  ngOnDestroy(): void {
    this.client?.disconnect();
  }

  copy(text: Element): void {
    this.clipboardService.copy(text.textContent ?? '');
    this.notificationService.showInfo('Payload copied');
  }

  exportFeed(): void {
    const file = new File(
      [
        JSON.stringify(
          this.messages().map(({ collapsed, payload, ...message }) => ({
            ...message,
            payload: JSON.parse(payload),
          })),
        ),
      ],
      `${this.thingName}.json`,
      {
        type: 'text/plain;charset=utf-8',
      },
    );

    FileSaver.saveAs(file);
  }

  clearFeed(): void {
    this.messages.set([]);
  }

  private getClient(credentials: Credentials): Client | undefined {
    const hostUri = this.sigV4UtilsService.SigV4Utils.getSignedUrl(
      CONFIG.iotEndpoint,
      CONFIG.awsRegion,
      credentials,
    );

    return new Client(hostUri, `adminconsole_${uuid.v4()}`);
  }

  private onMessageArrived(msg: Message): void {
    if (!this.isPaused) {
      this.messages.update((messages) => [
        IotMessage.fromRaw(msg.destinationName, msg.payloadString),
        ...messages,
      ]);
    }
  }

  private onConnectionLost(): void {
    this.client = undefined;
  }

  private onConnectionSuccess(): void {
    Promise.all([
      this.subscribeToTopic(
        `${this.thingData?.thingType}/${this.thingData?.serialNumber}/#`,
      ),
      this.subscribeToTopic(`$aws/things/${this.thingName}/#`),
    ])
      .then(() => {
        if (this.thingData) {
          this.showStatusInFeed(
            this.thingData.thingType,
            this.thingData.serialNumber,
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  }

  private onConnectionFailure(): void {
    this.client = undefined;
  }

  private subscribeToTopic(topicName: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!this.client || !this.client.isConnected()) {
        reject(new Error('Client not connected!'));
        return;
      }
      this.client.subscribe(topicName, {
        timeout: this.TIMEOUT,
        qos: this.QOS,
        onSuccess: () => {
          resolve();
        },
        onFailure: (error: ErrorWithInvocationContext) => {
          reject(new Error(error.errorMessage));
        },
      });
    });
  }

  private showStatusInFeed(thingType: string, serialNumber: string): void {
    let topic = `${thingType}/${serialNumber}/`;

    if (getStatusThings.thingsTypes.includes(thingType)) {
      topic += getStatusThings.endPoint;
    } else if (getCookingStatusThings.thingsTypes.includes(thingType)) {
      topic += getCookingStatusThings.endPoint;
    } else {
      return;
    }

    this.client?.send(topic, '{}', this.QOS);
  }
}
