import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import CONFIG from '../../../config';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import {
  TeamsFactsMessageCardSection,
  TeamsMessageCard,
  TeamsTextMessageCardSection,
} from '../../models/teams-message-card.model';
import { TeamsService } from '../../api/teams.service';
import { StoreService } from '../../lib/store.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NotificationService } from '../notification.service';
import { BackendError } from '../../models/backend/backend-error';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorDialogComponent {
  @Input() errorMessage?: string;
  @Input() errorDetails?: unknown;

  showHelpForm = false;
  helpFormGroup = new UntypedFormGroup({
    comments: new UntypedFormControl(),
  });

  sendingReport = false;

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly teamsService: TeamsService,
    private readonly store: StoreService,
    private readonly notifService: NotificationService,
  ) {}

  sendTeamsMessage(): void {
    this.sendingReport = true;

    const user = this.store.session?.tokens!.idToken!.payload;

    const message = new TeamsMessageCard(
      `[${CONFIG.title}] Error report`,
      'Error report',
    );

    message.themeColor = CONFIG.teamsMessageColor;

    const userMessageSection = new TeamsTextMessageCardSection(
      `[${user?.given_name} ${user?.family_name}](https://teams.microsoft.com/l/chat/0/0?users=${user?.email}) asked for help`,
      this.helpFormGroup.value.comments?.replace(/\n/g, '<br/>'),
    );
    userMessageSection.activitySubtitle = `About an error : ${this.errorMessage}`;
    message.sections.push(userMessageSection);

    const contextSection = new TeamsFactsMessageCardSection('Error Context :');
    contextSection.facts.push({
      name: 'Current URL',
      value: this.getCurrentUrl(),
    });
    contextSection.facts.push({
      name: 'Timestamp',
      value: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    });
    contextSection.facts.push({
      name: 'User agent',
      value: this.getUserAgent(),
    });
    contextSection.facts.push({
      name: 'Console version',
      value: environment.version,
    });
    message.sections.push(contextSection);

    if (this.errorDetails) {
      const errorDetailsSection = new TeamsTextMessageCardSection(
        'Error details :',
        JSON.stringify(this.errorDetails, null, 2)
          ?.replace(/\n/g, '<br/>')
          ?.replace(/\s/g, '&nbsp;'),
      );
      message.sections.push(errorDetailsSection);
    }

    this.teamsService.sendMessage(message).subscribe(
      () => {
        this.notifService.showSuccess('Error report successfully sent !');
        this.modal.close();
      },
      () =>
        this.notifService.showWarning(
          "Sorry, something went wrong and we couldn't " +
            'send your error report. Please contact us directly by email or Teams.',
        ),
    );
  }

  close(): void {
    this.modal.close();
  }

  getHumanReadableErrorDetails(): string[] {
    let result = [];

    if (typeof this.errorDetails === 'string') {
      result = [this.errorDetails];
    }

    if (this.errorDetails instanceof Array) {
      result = this.errorDetails.filter(
        (val: unknown): boolean => typeof val === 'string',
      );
    }

    if (this.errorDetails instanceof BackendError) {
      result = this.errorDetails.details;
    }

    return result;
  }

  private getCurrentUrl(): string {
    return window.location.href;
  }

  private getUserAgent(): string {
    return navigator.userAgent;
  }
}
