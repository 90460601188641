import { DocumentClient } from 'aws-sdk/clients/dynamodb';
import * as moment from 'moment';

export type JobType = 'SINGLE' | 'MULTI' | 'GROUP';

export class MetaVersionJob {
  metaversionId: string;
  jobId: string;
  date: Date;
  dateStr?: string;
  jobType: JobType;
  groupId?: string;

  constructor(
    metaversionId: string,
    jobId: string,
    date: Date,
    jobType: JobType,
    groupId?: string,
  ) {
    this.metaversionId = metaversionId;
    this.jobId = jobId;
    this.date = date;
    this.dateStr = moment(date).format('YYYY-MM-DD HH:mm:ss');
    this.jobType = jobType;
    this.groupId = groupId;
  }

  static mapDynamoOutputToMetaversionJobs(
    dynamoOutput: DocumentClient.ItemList,
  ): MetaVersionJob[] {
    return dynamoOutput.map(
      (item: DocumentClient.AttributeMap) =>
        new MetaVersionJob(
          item.metaversionId,
          item.jobId,
          new Date(item.date),
          item.jobType,
        ),
    );
  }
}
