import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AwsService } from '../lib/aws.service';
import { ListThingTypesResponse } from 'aws-sdk/clients/iot';

@Injectable({
  providedIn: 'root',
})
export class ThingTypesService {
  constructor(private aws: AwsService) {}

  getThingTypes(): Observable<string[]> {
    return from(this.aws.iot().listThingTypes().promise()).pipe(
      map(
        (response: ListThingTypesResponse) =>
          response.thingTypes?.map((value) => value.thingTypeName ?? '') ?? [],
      ),
    );
  }
}
