import { Injectable } from '@angular/core';
import {
  AuditAction,
  AuditLog,
  AuditType,
  MappedAuditLog,
  PaginatedSearchAuditLog,
  SearchAuditLog,
} from '@common/audit-log/models/AuditLog';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Utils } from '../../../../shared/utils';
import { StoreService } from '../../../../lib/store.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  private readonly backendUrl = environment.backendUrl;

  constructor(
    private readonly store: StoreService,
    private readonly http: HttpClient,
  ) {}

  /**
   * sends an Audit event to backend
   *
   * @param log
   */
  pushEvent(log: AuditLog | Partial<AuditLog>): void {
    const payload = this.store?.session?.tokens!.idToken!.payload;
    if (!payload) {
      console.error(
        'Could not send AuditLog. User session does not exist ?',
        this.store.session,
      );
      return;
    }

    log.date ??= new Date().toISOString();
    log.userId ??= payload?.['cognito:username'] as string;

    this.http.post<void>(`${this.backendUrl}/audit`, log).subscribe({
      error: (err) => {
        if (err instanceof Error) {
          console.error('Could no send Audit log', err.message);
        } else {
          console.error('Could no send Audit log', JSON.stringify(err), err);
        }
      },
    });
  }

  /**
   * gets latest audit event depending on provided type, action and resource id
   * resource id may be an array of ids
   * if there is more than 30 ids (arbitrary value, but 50 can't be processed) the request is chunked and combined into one observable
   *
   * @param type
   * @param action
   * @param resourceId
   * @param chunkSize
   */
  getLatestEvent(
    type: AuditType,
    action: AuditAction,
    resourceId: string | string[],
    chunkSize = 30,
  ): Observable<MappedAuditLog> {
    const resourceIds = Array.isArray(resourceId) ? resourceId : [resourceId];

    const chunkedAudits = Utils.splitArrayToFixedSizeChunks(
      resourceIds,
      chunkSize,
    ).map((_chunkedIds) =>
      this.http
        .get<AuditLog[]>(`${this.backendUrl}/audit/latest`, {
          params: {
            type,
            action,
            resourceId: _chunkedIds.join('|'),
          },
        })
        .pipe(
          map((logs) => {
            const entries = logs.map((log) => [log.resourceId, log]);
            return Object.fromEntries(entries) as MappedAuditLog;
          }),
        ),
    );

    return combineLatest(chunkedAudits).pipe(
      map((logs) =>
        logs.reduce(
          (aggregate, mappedLog) => ({ ...aggregate, ...mappedLog }),
          {},
        ),
      ),
    );
  }

  searchLogs(
    params: object,
    pageIndex: number,
    pageSize: number,
  ): Observable<PaginatedSearchAuditLog> {
    return this.http
      .get<PaginatedSearchAuditLog>(`${this.backendUrl}/audit`, {
        params: {
          ...params,
          pageIndex,
          pageSize,
        },
      })
      .pipe(
        map((logs) => {
          logs.data = logs.data.map((log) => new SearchAuditLog(log));
          return logs;
        }),
      );
  }
}
