import { DatabaseService } from '../api/database.service';
import { MacAddress, ThingData, ThingType } from '../models/thingtype';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { NotificationService } from '../shared/notification.service';
import { SearchIndexResponse } from 'aws-sdk/clients/iot';
import { RegistryService } from '../api/registry.service';
import { Shadow } from '../models/shadow';
import { UtilsService } from '../lib/utils.service';
import { ThingTypesService } from '../api/thing-types.service';
import { AddThingToGroupComponent } from '../groups-of-things/groups-of-things-list/add-thing-to-group/add-thing-to-group.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ThingListDisplay } from '../models/thing-list-display.model';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent implements OnInit {
  devices: ThingListDisplay[] = [];
  thingValue?: string;
  pageNumber = 0;
  disablePreviousPage = true;
  disableNextPage = false;
  macAddress?: MacAddress;
  isLoading = false;
  queryString?: string;
  searchResponses: SearchIndexResponse[] = [];
  thingTypes: ThingType[] = [];

  //// Devices informations
  shadow?: Shadow;
  lastConnection?: string;
  reportedFirmware?: string;
  reportedNextFirmware?: string;
  desiredNextFirmware?: string;

  readonly columns = [
    { key: 'name', title: 'Name', width: '25%' },
    { key: 'connectionTimeStr', title: 'Last Connection', width: '10%' },
    { key: 'currentFirmware', title: 'Current Firmware', width: '10%' },
    {
      key: 'nextFirmwareReported',
      title: 'Next Firmware (reported)',
      searchEnabled: true,
      width: '10%',
    },
    {
      key: 'nextFirmwareDesired',
      title: 'Next Firmware (desired)',
      orderEnabled: true,
      width: '10%',
    },
    {
      key: 'actions',
      title: 'Actions',
      orderEnabled: false,
      searchEnabled: false,
      width: '4%',
    },
  ] as Columns[];

  configuration: Config = {
    ...DefaultConfig,
    searchEnabled: true,
    paginationEnabled: false,
    rows: 1000000,
    orderEnabled: true,
    isLoading: this.isLoading,
  };

  formGroupLocalFilters = new UntypedFormGroup({
    name: new UntypedFormControl(),
    connectionTimeStr: new UntypedFormControl(),
    currentFirmware: new UntypedFormControl(),
    nextFirmwareReported: new UntypedFormControl(),
    nextFirmwareDesired: new UntypedFormControl(),
  });

  constructor(
    private router: Router,
    private clipboardService: ClipboardService,
    private notif: NotificationService,
    private searchService: RegistryService,
    private databaseService: DatabaseService,
    private utilsService: UtilsService,
    private readonly ngbModal: NgbModal,
    private thingTypesService: ThingTypesService,
  ) {}

  ngOnInit(): void {
    this.getThingTypes();
  }

  onChange(event: Event): void {
    this.thingValue = (event.target as HTMLOptionElement).value;
  }

  onChangeSearchValue(event: Event): void {
    const parts = (event.target as HTMLInputElement).value.split('-');
    const potentialThingType = parts[0];
    const potentialSerialNumber = parts.splice(1).join('-');
    let newThingType = this.thingValue;
    let newMacAddress = (event.target as HTMLInputElement).value;

    for (const thingType of this.thingTypes) {
      if (potentialThingType === thingType) {
        newThingType = potentialThingType as ThingType;
        newMacAddress = potentialSerialNumber;
        break;
      }
    }

    this.thingValue = newThingType;
    this.macAddress = newMacAddress.trim() as MacAddress;
  }

  copieKey(str: string): void {
    this.clipboardService.copy(str);
    this.showCopied();
  }

  showCopied(): void {
    this.notif.showInfo('Payload copied');
  }

  async search(str: string): Promise<void> {
    this.devices = [];
    this.isLoading = true;
    const searchResponses: SearchIndexResponse[] = [];

    if (!str) {
      this.notif.showError('invalid parameters');
      return;
    }

    try {
      // Search depending of fields values
      if (this.macAddress?.includes(':') === false) {
        this.queryString = this.makeSearchQueryString();
        if (this.queryString === '') {
          this.isLoading = false;
          return;
        }
        const searchResponse = await this.searchService.paginateListDevices(
          this.queryString,
        );
        searchResponses.push(searchResponse);
        this.disableNextPage = !searchResponse.nextToken;

        this.devices = ThingListDisplay.fromThingDocuments(
          searchResponses[0].things,
        );
      } else {
        if (!this.macAddress || this.macAddress.slice(-1) === '*') {
          this.notif.showError('Partial search by mac address is not allowed!');
          return;
        }
        const iotDevice = await this.databaseService.search(this.macAddress);
        await this.router.navigateByUrl(
          `/things/${iotDevice.thingType}-${iotDevice.serialnumber}`,
        );
        return;
      }
    } catch (e) {
      this.notif.showError((e as Error).message, e);
      this.isLoading = false;
      return;
    }

    if (!this.devices.length) {
      this.notif.showError('No devices');
      this.isLoading = false;
      return;
    }

    this.searchResponses = searchResponses;
    this.pageNumber = 0;
    this.disablePreviousPage = true;
    this.isLoading = false;
  }

  async nextDevices(): Promise<void> {
    const searchResponse: SearchIndexResponse =
      this.searchResponses[this.pageNumber];
    const nextPage: number = this.pageNumber + 1;

    if (searchResponse.nextToken) {
      this.isLoading = true;

      if (this.searchResponses.length > nextPage) {
        // Devices already listed
        this.isLoading = false;
        this.devices = ThingListDisplay.fromThingDocuments(
          this.searchResponses[nextPage].things,
        );
        this.pageNumber = this.pageNumber + 1;
        this.disableNextPage = !this.searchResponses[nextPage].nextToken;
        this.disablePreviousPage = false;
      } else {
        try {
          const newPageResponse = await this.searchService.paginateListDevices(
            this.queryString,
            searchResponse.nextToken,
          );
          this.searchResponses.push(newPageResponse);
          this.disableNextPage = !searchResponse.nextToken;

          this.devices = ThingListDisplay.fromThingDocuments(
            this.searchResponses[nextPage].things,
          );
        } catch (e) {
          this.notif.showError((e as Error).message, e);
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.pageNumber = this.pageNumber + 1;
        this.disablePreviousPage = false;
      }
    }

    window.scrollTo(0, 0);
  }

  previousDevices(): void {
    if (this.pageNumber > 0) {
      this.pageNumber--;
      this.devices = ThingListDisplay.fromThingDocuments(
        this.searchResponses[this.pageNumber].things,
      );
      window.scrollTo(0, 0);
      if (this.pageNumber === 0) {
        this.disablePreviousPage = true;
      }
      this.disableNextPage = false;
    }
  }

  displayDate(str: string): void {
    this.shadow = new Shadow(JSON.parse(str || '{}'));
    this.lastConnection = this.utilsService.formatDate(
      this.shadow.lastConnection(),
    );

    this.reportedFirmware =
      this.utilsService.getVersions(this.shadow.reportedFirmware()) || '-';

    this.reportedNextFirmware =
      this.utilsService.getVersions(this.shadow.reportedNextFirmware()) || '-';

    this.desiredNextFirmware =
      this.utilsService.getVersions(this.shadow.desiredNextFirmware()) || '-';
  }

  getThingTypes(): void {
    this.isLoading = true;
    this.thingTypesService.getThingTypes().subscribe(
      (thingTypes) => {
        this.thingTypes = thingTypes as ThingType[];
      },
      (err) => {
        this.notif.showError(err.message, err);
      },
      () => {
        this.isLoading = false;
      },
    );
  }

  async addThingToAGroup(thing: ThingListDisplay): Promise<void> {
    const modal = this.ngbModal.open(AddThingToGroupComponent, {
      backdrop: 'static',
      centered: true,
    });

    modal.componentInstance.thing = ThingData.fromThingListDisplay(thing);
  }

  private makeSearchQueryString(): string {
    const queryParams = [];

    // Thing type
    if (this.thingValue) {
      queryParams.push(`thingName:${this.thingValue}-${this.macAddress}`);
    } else {
      for (const thingType of this.thingTypes) {
        queryParams.push(`thingName:${thingType}-${this.macAddress}`);
      }
    }

    if (queryParams.length > 7) {
      this.notif.showError('Please select a Thing Type');
      return '';
    }

    return queryParams.join(' OR ');
  }
}
