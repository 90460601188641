import { Injectable } from '@angular/core';
import {
  CreateJobRequest,
  CreateJobResponse,
  DescribeThingGroupResponse,
} from 'aws-sdk/clients/iot';
import { AwsService } from '../lib/aws.service';
import { MetaVersion } from '../models/metaversion/meta-version';
import { ThingData, ThingGroup } from '../models/thingtype';
import { CriteriaKey } from '../models/firmware';
import { Observable } from 'rxjs';
import { from } from 'rxjs';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DeployService {
  constructor(private awsService: AwsService) {}

  static buildThingGroupName(
    thingType: string,
    criteriaKey: CriteriaKey,
  ): string {
    return `${thingType}_${criteriaKey}`.replace(/\./g, '-');
  }

  /**
   * Checks if the given metaversion is ready to create a Job for the deployment.<br/>
   * Returns an observable that will emit once, of all the Thing Groups associated with the metaversion are fetched ('described').
   * The s3CriteriaKeys of each firmware is used to determine the Thing Groups names and fetch them
   *
   * @throws an error if the statusCode of the response is a 404, which means the Thing Group doesn't exist
   * @param metaVersion the metaversion we want to deploy
   */
  public prepareJob(metaVersion: MetaVersion): Observable<ThingGroup[]> {
    const brandAreas = metaVersion.getFirmwareBrandArea();
    if (!brandAreas) {
      throw new Error(
        'You cannot deploy a version with distinct brand-area attribute per firmware!',
      );
    }

    // Ui firmware contains the criteria keys
    // but if there isn't any ui firmware, get the wifi ones
    // which should be only the default "NA_NA_NA_NA"
    // because wifi firmwares don't handle (yet) range/cmmf/technical increment/brand area
    const s3CriteriaKeys = metaVersion.getS3CriteriaKeys();
    if (s3CriteriaKeys.length === 0) {
      throw new Error('No Firmware has any associated file');
    }

    return combineLatest(
      s3CriteriaKeys.map((s3CriteriaKey) => {
        const _groupName = DeployService.buildThingGroupName(
          metaVersion.thingType,
          s3CriteriaKey,
        );
        return this.describeAndMapThingGroup(_groupName, s3CriteriaKey);
      }),
    );
  }

  describeAndMapThingGroup(
    thingGroupName: string,
    criteriaKey: CriteriaKey,
  ): Observable<ThingGroup> {
    return this.awsDescribeThingGroup(thingGroupName).pipe(
      map(
        (describeThingGroupResult) =>
          new ThingGroup(
            thingGroupName,
            describeThingGroupResult.thingGroupArn,
            criteriaKey,
            describeThingGroupResult.status,
          ),
      ),
    );
  }

  startJob(
    thingGroupsOrThings: ThingGroup[] | ThingData[],
    metaVersion: MetaVersion,
  ): Observable<CreateJobResponse> {
    const arns = thingGroupsOrThings
      .map((val) => {
        if (val instanceof ThingGroup) {
          return val.thingGroupArn;
        } else {
          return val.thingArn ?? '';
        }
      })
      .filter((arn) => arn.length);

    const builtId = this.buildJobId(metaVersion);

    const createJobParams: CreateJobRequest = {
      jobId: builtId,
      targets: arns,
      tags: [
        { Key: 'DOMAIN', Value: 'consumers' },
        { Key: 'ENVIRONMENT', Value: environment.envTag },
        { Key: 'APPLICATION', Value: 'IOT' },
        { Key: 'OWNER', Value: 'fctis-itdcpiot-internal@groupeseb.com' },
        { Key: 'COSTCENTER', Value: 'i39' },
        { Key: 'NAME', Value: builtId },
      ],
      document: JSON.stringify({
        nextFirmware: {
          version: metaVersion.id,
          updateValidation: metaVersion.updateValidation,
          validationAccepted: metaVersion.validationAccepted,
          priority: metaVersion.priority,
          files: metaVersion.getComponents(),
        },
      }),
    };

    return from(this.awsService.iot().createJob(createJobParams).promise());
  }

  /**
   * Builds the JobId name from a MetaVersion
   * Agglomerates a fixed prefix, the thing type, the metaversion's id and the timestamp
   * The MetaVersion's id is sanitized to remove all non alphanumeric and '-' or '_', and replace them by a '-'
   * Also, if the MetaVersion's id is truncated if longer than 102 characters
   *
   * @param metaVersion the metaversion to generate the jobId from
   */
  public buildJobId(metaVersion: MetaVersion): string {
    // maximum length the id can have is 35, deduced by :
    // max job id length (64) - "IOTJOBS" (7) - timestamp (13) - thingtype (6) - '_' separators (3)
    const maxIdLength = 35; // 64 - 29
    const sanitizedId = metaVersion.id
      ?.replace(/[^a-zA-Z0-9\-_]/g, '-')
      .substr(0, maxIdLength);

    return [
      'IOTJOBS',
      metaVersion.thingType,
      Date.now(),
      sanitizedId, // anything not matching a-Z, 0-9, '-' or '_' is replaced by '-'
    ].join('_');
  }

  private awsDescribeThingGroup(
    thingGroupName: string,
  ): Observable<DescribeThingGroupResponse> {
    return from(
      this.awsService.iot().describeThingGroup({ thingGroupName }).promise(),
    );
  }
}
