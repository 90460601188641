import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, switchMap } from 'rxjs';
import * as Auth from 'aws-amplify/auth';
import { StoreService } from '../../../../lib/store.service';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly storeService: StoreService) {}

  intercept(
    req: HttpRequest<unknown>,
    handler: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (!req.url.startsWith(environment.backendUrl)) {
      return handler.handle(req);
    }

    return from(this.getSession()).pipe(
      switchMap((session) => {
        const newReq = req.clone({
          headers: req.headers.set(
            'Authorization',
            session.tokens!.idToken!.toString(),
          ),
        });

        return handler.handle(newReq);
      }),
    );
  }

  private async getSession(): Promise<Auth.AuthSession> {
    if (!this.storeService.session?.tokens) {
      this.storeService.session = await Auth.fetchAuthSession();
    } else {
      const idToken = this.storeService.session.tokens.idToken;

      if (!idToken || this.isTokenExpired(idToken)) {
        this.storeService.session = await Auth.fetchAuthSession();
      }
    }

    return this.storeService.session;
  }

  private isTokenExpired(token: Auth.JWT): boolean {
    return !!token.payload.exp && Date.now() > token.payload.exp * 1000;
  }
}
