import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AwsService } from '../lib/aws.service';
import { from } from 'rxjs';
import { InvocationResponse } from 'aws-sdk/clients/lambda';

@Injectable({
  providedIn: 'root',
})
export class DeleteThingService {
  constructor(private awsService: AwsService) {}

  deleteThing(thingName: string): Observable<InvocationResponse> {
    return from(
      this.awsService
        .lambda()
        .invoke({
          FunctionName: 'DeleteThing',
          InvocationType: 'RequestResponse',
          Payload: JSON.stringify({
            body: JSON.stringify({ thingName }),
          }),
        })
        .promise(),
    );
  }
}
