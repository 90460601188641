import { take } from 'rxjs/operators';
import { StoreService } from '../../../lib/store.service';
import {
  Injectable,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { FeatureGroupEnum } from '../feature-group.enum';

@Injectable()
export abstract class AbstractGroupBasedDirective implements OnInit {
  protected expected = true;

  constructor(
    private readonly storeService: StoreService,
    private readonly template: TemplateRef<unknown>,
    private readonly view: ViewContainerRef,
  ) {}

  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngOnInit(): void {
    if (this.storeService.isInitialized()) {
      this.handleViewRendering();
    } else {
      //User not logged in, hide the item and re-check after login
      this.view.clear();

      this.storeService
        .getInitEventEmitter()
        .pipe(take(1))
        .subscribe(() => this.handleViewRendering());
    }
  }

  private handleViewRendering(): void {
    if (
      this.storeService.userHasGroup(this.getSpecificGroup()) === this.expected
    ) {
      this.view.createEmbeddedView(this.template);
    } else {
      this.view.clear();
    }
  }

  protected abstract getSpecificGroup(): FeatureGroupEnum;
}
