import {environment} from './environments/environment';

const CONFIG = {
  title: environment.title ?? '',
  apiUrl: environment.apiUrl ?? '',
  apiKey: environment.apiKey ?? '',
  backendUrl: environment.backendUrl ?? '',
  metaVersionsTable: environment.metaVersionsTable ?? '',
  metaVersionsIndex: environment.metaVersionsIndex ?? '',
  firmwaresTable: environment.firmwaresTable ?? '',
  firmwaresIndex: environment.firmwaresIndex ?? '',
  iotDevicesTable: environment.iotDevicesTable ?? '',
  iotDevicesIndex: environment.iotDevicesIndex ?? '',
  iotDevicesStatsTable: environment.iotDevicesStatsTable ?? '',
  firmwareBrandAreaTable: environment.firmwareBrandAreaTable ?? '',
  metaversionJobsTable: environment.metaversionJobsTable ?? '',
  metaversionJobsIndex: environment.metaversionJobsIndex ?? '',
  metaversionJobsGroupIdIndex: environment.metaversionJobsGroupIdIndex ?? '',
  groupOfThingsTable: environment.groupOfThingsTable ?? '',
  groupOfThingsThingTypeIndex: environment.groupOfThingsthingTypeIndex ?? '',
  firmwaresBucket: environment.firmwaresBucket ?? '',
  temporaryFirmwaresBucket: environment.temporaryFirmwaresBucket ?? '',
  userLoginHistoryTable: environment.userLoginHistoryTable ?? '',
  userHistoryTTL: environment.userHistoryTTL ?? 0,
  cognitoIdpName: environment.cognitoIdpName ?? '',
  cognitoUserPoolId: environment.cognitoUserPoolId ?? '',
  cognitoClientId: environment.cognitoClientId ?? '',
  cognitoIdentityPoolId: environment.cognitoIdentityPoolId ?? '',
  cognitoDomain: environment.cognitoDomain ?? '',
  cognitoSignInUrl: environment.cognitoSignInUrl ?? '',
  cognitoSignOutUrl: environment.cognitoSignOutUrl ?? '',
  cognitoReponseType: environment.cognitoReponseType ?? '',
  awsRegion: environment.awsRegion ?? '',
  iotEndpoint: environment.iotEndpoint ?? '',
  iotPolicyName: environment.iotPolicyName ?? '',
  unsignedFolder: environment.unsignedFolder ?? '',
  signedFolder: environment.signedFolder ?? '',
  searchMaxResults: Number(environment.searchMaxResults ?? 10),
  thingArnPrefix: environment.thingArnPrefix ?? '',
  crossOriginLambda: environment.crossOriginLambda,
  teamsWebhokUrl: environment.teamsWebhokUrl,
  teamsMessageColor: environment.teamsMessageColor,
  s3ProxyReadLambda: environment.s3ProxyReadLambda,
  s3ProxyWriteLambda: environment.s3ProxyWriteLambda
};

export default CONFIG;
