import { Injectable } from '@angular/core';
import { AwsService } from '../lib/aws.service';
import {
  GetCostAndUsageResponse,
  GroupDefinitions,
} from 'aws-sdk/clients/costexplorer';
import { AWSCosts, AWSCost } from '../models/AWSCost';
import { map } from 'rxjs/operators';
import { MonthRange } from '../models/month-range';
import CONFIG from '../../config';
import { AttributeMap, ScanOutput } from 'aws-sdk/clients/dynamodb';
import { MonthProductNumbers, ProductNumbers } from '../models/device-stats';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  constructor(private awsService: AwsService) {}

  /**
   * Returns monthly global costs per service from AWS
   *
   * @param range.startMonth start month format 'YYYY-MM', inclusive.
   * @param range.endMonth end month format 'YYYY-MM', exclusive.
   */
  getMonthlyCostsByService(range: MonthRange): Observable<AWSCosts> {
    const groupBy: GroupDefinitions = [
      {
        Type: 'DIMENSION',
        Key: 'SERVICE',
      },
    ];

    return this._callAWSCostsExplorer(range, groupBy).pipe(
      map(this._mapToCustomFormat),
      map(this._computeVariations),
      map(this._filterLowValues),
    );
  }

  /**
   * Returns monthly global costs from AWS
   *
   * @param range.startMonth start month format 'YYYY-MM', inclusive.
   * @param range.endMonth end month format 'YYYY-MM', exclusive.
   */
  getMonthlyCostsByTag(range: MonthRange): Observable<AWSCosts> {
    const groupBy: GroupDefinitions = [
      {
        Type: 'TAG',
        Key: 'Project',
      },
    ];

    return this._callAWSCostsExplorer(range, groupBy).pipe(
      map(this._mapToCustomFormat),
    );
  }

  _callAWSCostsExplorer(
    range: MonthRange,
    groupBy: GroupDefinitions,
  ): Observable<GetCostAndUsageResponse> {
    return from(
      this.awsService
        .costExplorer()
        .getCostAndUsage({
          TimePeriod: {
            End: `${range.endMonth}-01`,
            Start: `${range.startMonth}-01`,
          },
          Granularity: 'MONTHLY',
          Metrics: ['AmortizedCost'],
          GroupBy: groupBy,
          Filter: {
            Not: {
              Dimensions: {
                Key: 'RECORD_TYPE',
                Values: ['Tax'],
              },
            },
          },
        })
        .promise(),
    );
  }

  _mapToCustomFormat(res: GetCostAndUsageResponse, _index?: number): AWSCosts {
    const numberOfMonths = res.ResultsByTime?.length;

    const serviceCosts: { [serviceName: string]: AWSCost } = {};
    const monthLabels: string[] = [];
    const totals = Array(numberOfMonths).fill(0);
    const totalsWithoutTaxes = Array(numberOfMonths).fill(0);

    // Map AWS return object to custom object
    for (let i = 0; i < (res.ResultsByTime?.length ?? 0); i++) {
      const resByTime = res.ResultsByTime?.[i];

      const labelSplit = resByTime?.TimePeriod?.Start.split('-') ?? [];
      monthLabels[i] = `${labelSplit[1]} ${labelSplit[0]}`;
      if (resByTime?.Estimated) {
        monthLabels[i] += ' (Estimated)';
      }

      for (const group of resByTime?.Groups ?? []) {
        const serviceName = group.Keys?.[0].replace(
          /^(Amazon|AWS|Project\$)\s?/,
          '',
        );

        if (!serviceName) {
          continue;
        }

        if (!serviceCosts[serviceName]) {
          serviceCosts[serviceName] = {
            key: serviceName,
            monthCosts: Array(numberOfMonths).fill(0), // Initialize with an array of zeroes to account for missing values from AWS
          };
        }

        const amount = group.Metrics?.AmortizedCost.Amount;
        const cost = amount ? Number.parseFloat(amount) : 0;

        serviceCosts[serviceName].monthCosts[i] = cost;

        totals[i] += cost;

        if ('Tax' !== serviceName) {
          totalsWithoutTaxes[i] += cost;
        }
      }
    }

    return {
      monthLabels,
      costs: Object.values(serviceCosts),
      totals,
      totalsWithoutTaxes,
    };
  }

  _computeVariations(costs: AWSCosts, _index?: number): AWSCosts {
    // Initialize with a first undefined value as variation has no meaning fo the first column
    const variations: (number | undefined)[] = [undefined];

    for (let i = 1; i < costs.monthLabels.length; i++) {
      if (costs.totals?.[i] && costs.totals?.[i - 1]) {
        variations[i] =
          (costs.totals[i] - costs.totals[i - 1]) / costs.totals[i - 1];
      }
    }

    return {
      ...costs,
      variations,
    };
  }

  // Filter services with total costs lower than 1 and aggregate them into an "Others" category
  _filterLowValues(costs: AWSCosts, _index?: number): AWSCosts {
    const filteredCosts: AWSCost[] = [];

    const otherCosts: AWSCost = {
      key: 'Others',
      monthCosts: Array(costs.monthLabels.length).fill(0),
    };

    for (const serviceCost of costs.costs) {
      if (serviceCost.monthCosts.reduce((a, b) => a + b) < 1) {
        otherCosts.monthCosts = otherCosts.monthCosts.map(
          (val, index) => val + serviceCost.monthCosts[index],
        );
      } else {
        filteredCosts.push(serviceCost);
      }
    }

    // Check if there are actually any costs in the "Others" category
    if (otherCosts.monthCosts.reduce((a, b) => a + b)) {
      filteredCosts.push(otherCosts);
    }

    return {
      ...costs,
      costs: filteredCosts,
    };
  }

  /**
   * Scans the IOTDeviceStats dynamoDB table, then aggregates data, to return the monthly number of devices per type
   *
   * @param range.startMonth start month format 'YYYY-MM', inclusive.
   * @param range.endMonth end month format 'YYYY-MM', exclusive.
   * @return The monthly number of devices per type
   */
  getTotalNumberOfDevicesPerMonth(
    range: MonthRange,
  ): Observable<MonthProductNumbers> {
    return from(
      this.awsService
        .dynamodb()
        .scan({
          TableName: CONFIG.iotDevicesStatsTable,
          FilterExpression: '#day < :maxMonth',
          ExpressionAttributeNames: {
            '#day': 'day',
          },
          ExpressionAttributeValues: {
            ':maxMonth': range.endMonth,
          },
        })
        .promise(),
    ).pipe(map((scan) => this._mapToMonthProductNumbers(scan, range)));
  }

  _mapToMonthProductNumbers(
    scan: ScanOutput,
    range: MonthRange,
  ): MonthProductNumbers {
    let monthLabels: string[] = [];
    const productNumbers: { [key: string]: ProductNumbers } = {};
    let totals: number[] = [];

    // Group values by month
    const groupedByMonth: { [month: string]: AttributeMap[] } =
      scan.Items?.reduce(
        (
          acc: { [month: string]: AttributeMap[] },
          item: AttributeMap,
        ): { [month: string]: AttributeMap[] } => {
          const dateSplits = (item.day as string).split('-');
          const month = `${dateSplits[1]} ${dateSplits[0]}`;

          if (!acc[month]) {
            acc[month] = [item];
          } else {
            acc[month].push(item);
          }

          return acc;
        },
        {},
      ) ?? {};

    // Build month keys array
    monthLabels = this._buildMonthKeysArrayFromRange(range);

    totals = Array(monthLabels.length).fill(0);

    // Iterate values and increment numbers
    Object.keys(groupedByMonth).forEach((month) => {
      let monthIndex = monthLabels.indexOf(month);

      // All products that are before the start date have to be counted in the first column
      if (monthIndex === -1) {
        monthIndex = 0;
      }

      groupedByMonth[month]?.forEach((item) => {
        Object.keys(item)
          .filter((key) => key.endsWith('_count'))
          .map((key) => key.replace('_count', ''))
          .forEach((productKey) => {
            if (!productNumbers[productKey]) {
              productNumbers[productKey] = {
                productName: productKey,
                monthNumbers: Array(monthLabels.length).fill(0),
              };
            }

            if (!productNumbers[productKey].monthNumbers[monthIndex]) {
              productNumbers[productKey].monthNumbers[monthIndex] = 0;
            }

            const productNumber = item[productKey + '_count'] as number;

            for (let i = monthIndex; i < monthLabels.length; i++) {
              productNumbers[productKey].monthNumbers[i] += productNumber;
              totals[i] += productNumber;
            }
          });
      });
    });

    return {
      monthLabels,
      productsNumbers: Object.values(productNumbers),
      totals,
    };
  }

  _buildMonthKeysArrayFromRange(range: MonthRange): string[] {
    if (range.endMonth <= range.startMonth) {
      throw new Error('Invalid month range');
    }

    const result: string[] = [];

    const startMonthSplits = range.startMonth.split('-');

    let year = Number.parseInt(startMonthSplits[0], 10);
    let month = Number.parseInt(startMonthSplits[1], 10);

    while (`${year}-${month.toString().padStart(2, '0')}` < range.endMonth) {
      result.push(`${month.toString().padStart(2, '0')} ${year}`);

      if (month < 12) {
        month++;
      } else {
        month = 1;
        year++;
      }
    }

    return result;
  }
}
