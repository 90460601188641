import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Hub } from 'aws-amplify/utils';
import { AuditAction, AuditType } from '@common/audit-log/models/AuditLog';
import { map } from 'rxjs/operators';
import { AuthorizationService } from '../api/authorization.service';
import { AuditService } from '../api/backend/services/audit/audit.service';
import { UserLoginHistoryService } from '../api/user-login-history.service';
import { NotificationService } from '../shared/notification.service';
import * as Auth from 'aws-amplify/auth';
import 'aws-amplify/auth/enable-oauth-listener';

@Component({
  selector: 'app-after-login-redirection',
  templateUrl: './after-login-redirection.component.html',
  styleUrls: ['./after-login-redirection.component.css'],
})
export class AfterLoginRedirectionComponent implements OnInit {
  constructor(
    private auth: AuthorizationService,
    private userLoginHistoryService: UserLoginHistoryService,
    private auditService: AuditService,
    private notif: NotificationService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    //callback function type is not clearly defined in amplify, ignore warnings
    //eslint-disable-next-line
    //@ts-ignore
    Hub.listen('auth', async ({ payload: { event, message } }) => {
      switch (event) {
        case 'signedIn':
          try {
            const session = await this.auth.validateUserSession();

            this.userLoginHistoryService
              .saveUserLogin(session)
              .pipe(
                map(() => {
                  const payload = session.tokens!.idToken!.payload;
                  const identity: { userId: string; dateCreated: string } =
                    //@ts-expect-error identities object is not clearly defined in amplify, ignore warnings
                    payload.identities[0];
                  const log = {
                    type: AuditType.USER,
                    action: AuditAction.LOGIN,
                    userId: payload['cognito:username'] as string,
                    resourceId: payload['cognito:username'] as string,
                    date: new Date().toISOString(),
                    additionalData: {
                      seb_id: identity.userId,
                      sub: payload.sub!,
                      account_created_at: new Date(
                        parseInt(identity.dateCreated, 10),
                      ).toISOString(),
                      email: payload.email as string,
                      firstname: payload.given_name as string,
                      lastname: payload.family_name as string,
                    },
                  };

                  this.auditService.pushEvent(log);

                  return payload;
                }),
              )
              .subscribe((payload) => {
                const username = `${payload.given_name} ${payload.family_name}`;
                this.notif.showSuccess(`Hello ${username}!`);

                this.router.navigateByUrl('/');
              });
          } catch (e) {
            this.notif.showError((e as Error).message, e);
          }
          break;
        case 'signedOut':
          this.notif.showSuccess('User signed out !');
          break;
        case 'signInWithRedirect_failure':
          this.notif.showError('User sign in failed:' + message);
          this.router.navigateByUrl('/login');
          break;
      }
    });

    Auth.getCurrentUser().then((user) => {
      if (user) {
        this.router.navigateByUrl('/');
      }
    });
  }
}
